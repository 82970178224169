import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AppContext } from "../contexts/AppContext"
import Icons from "../utils/Icons"
import ProfitLossCell from "./ProfitLossCell"

const thStyle = `border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left`
const tdStyle = `border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-left`

const SummaryTable = () => {
    const { positions, userSettings, loading } = useContext(AppContext)
    const [data, setData] = useState(null)
    const [summary, setSummary] = useState(null)

    useEffect(() => {
        if (loading) {
            setData(null)
            return
        }

        let tmpData = {}
        let tmpSummary = {
            pnl: 0,
            commission: 0,
            opened: 0,
            closed: 0,
        }

        for (let symbol of Object.keys(positions)) {
            let pnlUSD = 0, pnlP = 0, commission = 0

            for (let pos of positions[symbol].closed) {
                pnlUSD += pos.total
                commission += pos.commission
            }

            pnlP = (pnlUSD * 100) / userSettings.symbols[symbol].deposit

            tmpData[symbol] = {
                pnlUSD, commission, pnlP
            }

            tmpSummary.commission += commission
            tmpSummary.pnl += pnlUSD
            tmpSummary.opened += positions[symbol].opened.length
            tmpSummary.closed += positions[symbol].closed.length
        }

        tmpSummary.pnlP = (tmpSummary.pnl * 100) / userSettings.deposit

        setSummary(prev => ({ ...tmpSummary }))
        setData(prev => ({ ...tmpData }))
    }, [positions, userSettings, loading])

    return (data && !loading) ? (
        <table className="border-collapse table-auto w-full text-sm">
            <thead>
                <tr>
                    <th className={thStyle}>Symbol</th>
                    <th className={thStyle}>Deposit $</th>
                    <th className={thStyle}>PNL $</th>
                    <th className={thStyle}>PNL %</th>
                    <th className={thStyle}>Opened</th>
                    <th className={thStyle}>Closed</th>
                    <th className={thStyle}>Commission</th>
                    <th className={thStyle}>Trade Settings</th>
                </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
                {
                    Object.keys(data).map(symbol => {
                        return (
                            <tr key={symbol}>
                                <td className={tdStyle}>
                                    <div className="flex items-center">
                                        <span className={`w-2 h-2 mr-2 rounded-full ${userSettings.symbols[symbol].stop ? 'bg-red-500' : 'bg-green-500'}`}></span>
                                        <img src={`${Icons[symbol.toUpperCase().split('USDT')[0]]}`} alt={symbol} className="w-8 h-8 mr-4" />
                                        <Link to={`/symbol/${symbol.toUpperCase()}`}>{symbol}</Link>
                                    </div>
                                </td>
                                <td className={tdStyle}>
                                    ${userSettings.symbols[symbol].deposit}
                                </td>
                                <td className={`${tdStyle} ${(data[symbol].pnlUSD < 0) ? 'text-red-500' : 'text-green-500'}`}>
                                    ${data[symbol].pnlUSD.toFixed(2)}
                                </td>
                                <td className={`${tdStyle} ${(data[symbol].pnlUSD < 0) ? 'text-red-500' : 'text-green-500'}`}>
                                    {data[symbol].pnlP.toFixed(2)}%
                                </td>
                                <td className={tdStyle}>
                                    {positions[symbol].opened.length ? (
                                        <div className="flex flex-row items-center justify-start">
                                            <div className={`rounded-md px-2 text-white ${positions[symbol].opened[0].side === 'BUY' ? 'bg-green-500' : 'bg-red-500'}`}>
                                                {(positions[symbol].opened[0]).side === 'BUY' ? 'L' : 'S'}
                                            </div>
                                            <div className="pl-2"><ProfitLossCell symbol={symbol} position={positions[symbol].opened[0]} type="amount" /> (<ProfitLossCell symbol={symbol} position={positions[symbol].opened[0]} type="percentage" />)</div>
                                        </div>
                                    ) : 0}
                                </td>
                                <td className={tdStyle}>
                                    {positions[symbol].closed.length}
                                </td>
                                <td className={tdStyle}>
                                    ${data[symbol].commission.toFixed(6)}
                                </td>
                                <td className={tdStyle}>
                                    {
                                        positions[symbol].opened[0] && positions[symbol].opened[0].strategy === 'ctx' && `CTX (Size: ${userSettings.symbols[symbol]?.ctx.size} | ${(userSettings.symbols[symbol]?.ctx.take) ? 'TP: ' + userSettings.symbols[symbol]?.ctx.take + '%' : (userSettings.symbols[symbol]?.ctx.trailingStop) ? 'TS: ap=' + userSettings.symbols[symbol]?.ctx.trailingStop.activationPrice + '% cr=' + userSettings.symbols[symbol]?.ctx.trailingStop.callbackRate + '%' : ''})`
                                    }
                                    {
                                        positions[symbol].opened[0] && positions[symbol].opened[0].strategy === 'fbr' && `FBR (TL1: ${positions[symbol].opened[0].take_limits[0]} | TL2: ${positions[symbol].opened[0].take_limits[1]} | TP: ${positions[symbol].opened[0].take})`
                                    }
                                </td>                                
                            </tr>
                        )
                    })
                }
                <tr className="bg-gray-100 border-t-2 border-gray-200">
                    <td className={tdStyle}>TOTAL:</td>
                    <td className={tdStyle}>
                        ${userSettings.deposit}
                    </td>
                    <td className={`${tdStyle} ${(summary.pnl < 0) ? 'text-red-500' : 'text-green-500'}`}>
                        ${summary.pnl.toFixed(2)}
                    </td>
                    <td className={`${tdStyle} ${(summary.pnl < 0) ? 'text-red-500' : 'text-green-500'}`}>
                        {summary.pnlP.toFixed(2)}%
                    </td>
                    <td className={tdStyle}>
                        {summary.opened}
                    </td>
                    <td className={tdStyle}>
                        {summary.closed}
                    </td>
                    <td className={tdStyle}>
                        ${summary.commission.toFixed(6)}
                    </td>
                    <td className={tdStyle}></td>
                </tr>
            </tbody>
        </table>
    ) : (<></>)
}

export default SummaryTable