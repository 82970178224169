import { Outlet } from "react-router-dom"
import { TickerContextProvider } from "../contexts/TickerContext"
import Header from "./Header"
import NavBar from "./NavBar"

const Layout = () => {
    return (
        <>
            <NavBar />
            <TickerContextProvider>
                <Header />
            </TickerContextProvider>
            <main>
                <div className="w-full xlg:max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                    <div className="px-4 py-6 sm:px-0">
                        <Outlet />
                    </div>
                </div>
            </main>
        </>
    )
}

export default Layout