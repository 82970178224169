const Icons = {
    "CSP": "https://assets-currency.kucoin.com/60bf8d40db892b0006d7380d_CSP.png",
    "LOKI": "https://assets-currency.kucoin.com/60c75945db892b0006d81d0a_OXEN.png",
    "NRG": "https://assets-currency.kucoin.com/60c74f7d8afb0a00068fe36d_NRG.png",
    "FET": "https://assets-currency.kucoin.com/60bf928f8afb0a00068eff0f_FET.png",
    "XMR": "https://assets-currency.kucoin.com/5c86109438300c31d25f7040_monero-token.png",
    "RBTC": "https://assets-currency.kucoin.com/60c344788afb0a00068f7720_RBTC.png",
    "RIF": "https://assets-currency.kucoin.com/60c344878afb0a00068f7723_RIF.png",
    "ANKR": "https://assets-currency.kucoin.com/60bf88188afb0a00068efd5c_ANKR.png",
    "MTV": "https://assets-currency.kucoin.com/60c74b48db892b0006d81ad0_MTV.png",
    "CRO": "https://assets-currency.kucoin.com/60bf8cfc8afb0a00068efe1a_CRO.png",
    "OPT": "https://assets-currency.kucoin.com/60c758c2db892b0006d81cfb_OPT.png",
    "KMD": "https://assets-currency.kucoin.com/60ee98564916a50006c22fb0_KMD.png",
    "RFOX": "https://assets-currency.kucoin.com/60c344968afb0a00068f7728_RFOX.png",
    "TT": "https://assets-currency.kucoin.com/60c35beddb892b0006d7b374_TT.png",
    "ATOM": "https://assets-currency.kucoin.com/60bf88958afb0a00068efd73_ATOM.png",
    "CHR": "https://assets-currency.kucoin.com/60bf8c368afb0a00068efdfa_CHR.png",
    "NIM": "https://assets-currency.kucoin.com/60c74efe8afb0a00068fe35c_NIM.png",
    "OCEAN": "https://assets-currency.kucoin.com/60c7519ddb892b0006d81bc6_OCEAN.png",
    "COTI": "https://assets-currency.kucoin.com/60bf8ccddb892b0006d737f7_COTI.png",
    "FX": "https://assets-currency.kucoin.com/60bf9333db892b0006d7391d_FX.png",
    "PIVX": "https://assets-currency.kucoin.com/60c2fcf68afb0a00068f6db7_PIVX.png",
    "XTZ": "https://assets-currency.kucoin.com/60c34ea68afb0a00068f78c7_XTZ.png",
    "BNB": "https://assets-currency.kucoin.com/60bf89c28afb0a00068efd99_BNB.png",
    "JAR": "https://assets-currency.kucoin.com/60c7430cdb892b0006d81989_JAR.png",
    "ALGO": "https://assets-currency.kucoin.com/60bf877f8afb0a00068efd43_ALGO.png",
    "ADA": "https://assets-currency.kucoin.com/60bf86838afb0a00068efd1d_ADA.png",
    "XEM": "https://assets-currency.kucoin.com/60c363fb8afb0a00068f7c79_XEM.png",
    "CIX100": "https://assets-currency.kucoin.com/60bf8c668afb0a00068efe06_CIX100.png",
    "ZEC": "https://assets-currency.kucoin.com/5d1b330ac29cc606c485e20e_Zcash-%28ZEC%29-token-logo-.png",
    "WXT": "https://assets-currency.kucoin.com/60c35c1ddb892b0006d7b37c_WXT.png",
    "FORESTPLUS": "https://assets-currency.kucoin.com/60bf92e38afb0a00068eff26_FORESTPLUS.png",
    "BOLT": "https://assets-currency.kucoin.com/60bf8a118afb0a00068efdaa_BOLT.png",
    "ARPA": "https://assets-currency.kucoin.com/60bf88638afb0a00068efd61_ARPA.png",
    "CHZ": "https://assets-currency.kucoin.com/60bf8c598afb0a00068efe03_CHZ.png",
    "NOIA": "https://assets-currency.kucoin.com/60c74f4b8afb0a00068fe367_NOIA.png",
    "DAPPT": "https://assets-currency.kucoin.com/60bf8e348afb0a00068efe4e_DAPPT.png",
    "EOSC": "https://assets-currency.kucoin.com/60bf917bdb892b0006d738d6_EOSC.png",
    "DERO": "https://assets-currency.kucoin.com/60bf8f02db892b0006d73878_DERO.png",
    "WIN": "https://assets-currency.kucoin.com/60c363c58afb0a00068f7c6c_WIN.png",
    "FKX": "https://assets-currency.kucoin.com/62566d9372efd5000133b6ec_FortKnoxster-Icon-Blue-256.png",
    "ENQ": "https://assets-currency.kucoin.com/60bf90e68afb0a00068efec9_ENQ.png",
    "THETA": "https://assets-currency.kucoin.com/60c35cb28afb0a00068f7b28_THETA.png",
    "ONE": "https://assets-currency.kucoin.com/60c758348afb0a00068fe4aa_ONE.png",
    "TOKO": "https://assets-currency.kucoin.com/60cac5478afb0a00069063b7_TOKO%20logo1%20%281%29.png",
    "TFUEL": "https://assets-currency.kucoin.com/60c35cd08afb0a00068f7b31_TFUEL.png",
    "LOL": "https://assets-currency.kucoin.com/60c745748afb0a00068fe1e4_LOL.png",
    "LUNA": "https://assets-currency.kucoin.com/62ba60d70d661a0001bd9171_2.png",
    "VID": "https://assets-currency.kucoin.com/5d638c3038300c2b63f7e8a2_VideoCoin_token.png",
    "MXW": "https://assets-currency.kucoin.com/60c74e268afb0a00068fe34a_MXW.png",
    "SXP": "https://assets-currency.kucoin.com/5d8b06a6c29cc63d8433b32c_Swipe-logo-circle%20-%20Cha%20Whale.png",
    "VIDT": "https://assets-currency.kucoin.com/5d916f97134ab727b23a89d5__V-ID%28VIDT%29-token-logo-.png",
    "AKRO": "https://assets-currency.kucoin.com/60bf873edb892b0006d7370a_AKRO.png",
    "ROOBEE": "https://assets-currency.kucoin.com/5da68ea238300c2b63f8bd2a_Skype_Picture_2019_10_16T03_23_56_133Z.jpeg",
    "AMPL": "https://assets-currency.kucoin.com/5dbf91dc25325400087a6776_logo-A.png",
    "MAP": "https://assets-currency.kucoin.com/60c7468d8afb0a00068fe20c_MAP.png",
    "COS": "https://assets-currency.kucoin.com/60bf8cbedb892b0006d737f4_COS.png",
    "POL": "https://assets-currency.kucoin.com/60c2fd408afb0a00068f6db8_POL.png",
    "ARX": "https://assets-currency.kucoin.com/60bf8883db892b0006d73737_ARX.png",
    "NWC": "https://assets-currency.kucoin.com/603f3eb2d04a880006ea8638_Logo%20transp.png",
    "BEPRO": "https://assets-currency.kucoin.com/60bf899adb892b0006d73767_BEPRO.png",
    "VRA": "https://assets-currency.kucoin.com/5dfca70e4a338000093e0d8f_Verasity%20Logo%2096%20x%2096.png",
    "KSM": "https://assets-currency.kucoin.com/60c7442a8afb0a00068fe1ca_KSM.png",
    "SUTER": "https://assets-currency.kucoin.com/60c34fa4db892b0006d7b18a_SUTER.png",
    "ACOIN": "https://assets-currency.kucoin.com/60bf865edb892b0006d736f0_ACOIN.png",
    "VI": "https://assets-currency.kucoin.com/5f59a22825cce60006d9359e_Group%20373%20%281%29.png",
    "AXE": "https://assets-currency.kucoin.com/60bf88dddb892b0006d7374b_AXE.png",    
    "STEEM": "https://assets-currency.kucoin.com/60c34fc78afb0a00068f7902_STEEM.png",
    "SENSO": "https://assets-currency.kucoin.com/60c34fd78afb0a00068f7904_SENSO.png",
    "PRE": "https://assets-currency.kucoin.com/60c2fd8b8afb0a00068f6dbd_PRE.png",
    "XDB": "https://assets-currency.kucoin.com/60c34fe5db892b0006d7b1a1_XDB.png",
    "SYLO": "https://assets-currency.kucoin.com/60c34ff1db892b0006d7b1a2_SYLO.png",
    "WOM": "https://assets-currency.kucoin.com/60c35d658afb0a00068f7b41_WOM.png",
    "LYXE": "https://assets-currency.kucoin.com/60c7463c8afb0a00068fe1fa_LYXe.png",
    "CADH": "https://assets-currency.kucoin.com/5ec2328cd83f760008758536_cadh-v-light.png",
    "JST": "https://assets-currency.kucoin.com/60c743278afb0a00068fe1a3_JST.png",
    "STX": "https://assets-currency.kucoin.com/5fa3669ef7b4c20006fb26ef_Stacks%20Logo.png",
    "USDN": "https://assets-currency.kucoin.com/60c35004db892b0006d7b1a7_USDN.png",
    "XSR": "https://assets-currency.kucoin.com/60c350118afb0a00068f790f_XSR.png",
    "COMP": "https://assets-currency.kucoin.com/60bf8caedb892b0006d737f3_COMP.png",
    "DGTX": "https://assets-currency.kucoin.com/60bf8f328afb0a00068efe82_DGTX.png",
    "KAI": "https://assets-currency.kucoin.com/60c7434ddb892b0006d8199c_KAI.png",
    "DOT": "https://assets-currency.kucoin.com/60bf8fe5db892b0006d73896_DOT.png",
    "EWT": "https://assets-currency.kucoin.com/60bf9250db892b0006d738f7_EWT.png",
    "WEST": "https://assets-currency.kucoin.com/60c352ce8afb0a00068f7989_WEST.png",
    "NVT": "https://assets-currency.kucoin.com/60c7516ddb892b0006d81bc4_NVT.png",
    "BNS": "https://assets-currency.kucoin.com/60bf89dbdb892b0006d7376d_BNS.png",
    "ORN": "https://assets-currency.kucoin.com/60c75917db892b0006d81d06_ORN.png",
    "PNK": "https://assets-currency.kucoin.com/60c2fdb98afb0a00068f6dbe_PNK.png",
    "WAVES": "https://assets-currency.kucoin.com/60c352d9db892b0006d7b203_WAVES.png",
    "SUKU": "https://assets-currency.kucoin.com/62a93ebfb46cd60001df44dd_suku-200.png",
    "MLK": "https://assets-currency.kucoin.com/60c74a388afb0a00068fe283_MLK.png",
    "DIA": "https://assets-currency.kucoin.com/60bf8f538afb0a00068efe87_DIA.png",
    "SHA": "https://assets-currency.kucoin.com/60c353098afb0a00068f798f_SHA.png",
    "LINK": "https://assets-currency.kucoin.com/60c744a2db892b0006d819d4_LINK.png",
    "USDJ": "https://assets-currency.kucoin.com/60c35316db892b0006d7b207_USDJ.png",
    "ALEPH": "https://assets-currency.kucoin.com/60bf875f8afb0a00068efd42_ALEPH.png",
    "EFX": "https://assets-currency.kucoin.com/60bf9074db892b0006d738a5_EFX.png",
    "CKB": "https://assets-currency.kucoin.com/60bf8c73db892b0006d737e7_CKB.png",
    "UMA": "https://assets-currency.kucoin.com/60c363048afb0a00068f7c57_UMA.png",
    "VELO": "https://assets-currency.kucoin.com/60c363348afb0a00068f7c59_VELO.png",
    "SUN": "https://assets-currency.kucoin.com/60e2a316486fc5000612a13b_MicrosoftTeams-image.png",
    "BUY": "https://assets-currency.kucoin.com/60bf8b2e8afb0a00068efdc9_BUY.png",
    "YFI": "https://assets-currency.kucoin.com/5f5b1b2525cce60006d93b74_yfi-192x192.png",
    "UNI": "https://assets-currency.kucoin.com/60c3532edb892b0006d7b210_UNI.png",
    "SNTVT": "https://assets-currency.kucoin.com/60c3533bdb892b0006d7b215_SNTVT.png",
    "UOS": "https://assets-currency.kucoin.com/60c35346db892b0006d7b217_UOS.png",
    "SATT": "https://assets-currency.kucoin.com/60c353508afb0a00068f799a_SATT.png",
    "KTS": "https://assets-currency.kucoin.com/5f6c835544c2e7000655f64f_126B5DFE-61BB-42EA-BF86-113748C55152.png",
    "DEGO": "https://assets-currency.kucoin.com/60bf8ec28afb0a00068efe6c_DEGO.png",
    "UDOO": "https://assets-currency.kucoin.com/5f86d52e25cce60006d9a645_Hyprr%20png.png",
    "RFUEL": "https://assets-currency.kucoin.com/60c344ea8afb0a00068f773a_RFUEL.png",
    "UBX": "https://assets-currency.kucoin.com/60c362f2db892b0006d7b4ca_UBX.png",
    "FIL": "https://assets-currency.kucoin.com/60bf929ddb892b0006d73906_FIL.png",
    "COMB": "https://assets-currency.kucoin.com/60bf8c9d8afb0a00068efe13_COMB.png",
    "REAP": "https://assets-currency.kucoin.com/60c344fadb892b0006d7afc2_REAP.png",
    "AAVE": "https://assets-currency.kucoin.com/60bf858b8afb0a00068efcf9_AAVE.png",
    "TONE": "https://assets-currency.kucoin.com/620c654b5eef2b000158dbf0_tefood-logo-500x500.png",
    "OPCT": "https://assets-currency.kucoin.com/60c758998afb0a00068fe4be_OPCT.png",
    "UQC": "https://assets-currency.kucoin.com/60c362e2db892b0006d7b4c8_UQC.png",
    "SHR": "https://assets-currency.kucoin.com/60c353668afb0a00068f799d_SHR.png",
    "UBXT": "https://assets-currency.kucoin.com/60c35372db892b0006d7b21a_UBXT.png",
    "ROSE": "https://assets-currency.kucoin.com/60c3537ddb892b0006d7b21d_ROSE.png",
    "UST": "https://assets-currency.kucoin.com/60c3538c8afb0a00068f79a3_UST.png",
    "CTI": "https://assets-currency.kucoin.com/60bf8d4fdb892b0006d73810_CTI.png",
    "ETH2": "https://assets-currency.kucoin.com/60bf920bdb892b0006d738e5_ETH2.png",
    "BUX": "https://assets-currency.kucoin.com/60bf8b1edb892b0006d73799_BUX.png",
    "XHV": "https://assets-currency.kucoin.com/60c35db58afb0a00068f7b4c_XHV.png",
    "PLU": "https://assets-currency.kucoin.com/60c2fe5c8afb0a00068f6dca_PLU.png",
    "CAS": "https://assets-currency.kucoin.com/60bf8bc18afb0a00068efde0_CAS.png",
    "GRT": "https://assets-currency.kucoin.com/60bf93cadb892b0006d73936_GRT.png",
    "MSWAP": "https://assets-currency.kucoin.com/60c74a5e8afb0a00068fe28e_MSWAP.png",
    "GOM2": "https://assets-currency.kucoin.com/60bf93a1db892b0006d73930_GOM2.png",
    "REVV": "https://assets-currency.kucoin.com/60c34527db892b0006d7afc3_REVV.png",
    "LON": "https://assets-currency.kucoin.com/60c74593db892b0006d819eb_LON.png",
    "API3": "https://assets-currency.kucoin.com/60bf883b8afb0a00068efd5e_API3.png",
    "SUSHI": "https://assets-currency.kucoin.com/60c3539b8afb0a00068f79a4_SUSHI.png",
    "UNFI": "https://assets-currency.kucoin.com/619470ea3b4d8400062c2f19_7672-removebg-preview.png",
    "ALPA": "https://assets-currency.kucoin.com/60bf87948afb0a00068efd48_ALPA.png",
    "1INCH": "https://assets-currency.kucoin.com/60bf85728afb0a00068efcf6_1INCH.png",
    "HTR": "https://assets-currency.kucoin.com/60c03f1ddb892b0006d74d86_HTR.png",
    "FRONT": "https://assets-currency.kucoin.com/60bf93178afb0a00068eff2f_FRONT.png",
    "WBTC": "https://assets-currency.kucoin.com/5ff7ffd4860ac800068a0236_WBTC.png",
    "HYDRA": "https://assets-currency.kucoin.com/60c740c9db892b0006d81952_HYDRA.png",
    "MIR": "https://assets-currency.kucoin.com/60c747698afb0a00068fe22e_MIR.png",
    "DFI": "https://assets-currency.kucoin.com/60bf8f16db892b0006d7387b_DFI.png",
    "FRM": "https://assets-currency.kucoin.com/60bf930adb892b0006d73916_FRM.png",
    "CRV": "https://assets-currency.kucoin.com/60bf8d1edb892b0006d73807_CRV.png",
    "BTC3L": "https://assets-currency.kucoin.com/6185f87e8d6d9e0006008a35_BTC3L.png",
    "BTC3S": "https://assets-currency.kucoin.com/6185f888db9c2c0006a717f6_BTC3S.png",
    "ETH3L": "https://assets-currency.kucoin.com/6185f8b0db9c2c0006a71805_ETH3L.png",
    "ETH3S": "https://assets-currency.kucoin.com/6185f8b8db9c2c0006a71807_ETH3S.png",
    "GZIL": "https://assets-currency.kucoin.com/60bf93ebdb892b0006d7393b_GZIL.png",
    "ZEN": "https://assets-currency.kucoin.com/60c364aa8afb0a00068f7c9c_ZEN.png",
    "CUDOS": "https://assets-currency.kucoin.com/60bf8d608afb0a00068efe2d_CUDOS.png",
    "MAP2": "https://assets-currency.kucoin.com/602dc5c895d4bc000652b404_Lounge%20M%20Symbol.png",
    "REN": "https://assets-currency.kucoin.com/60c3453bdb892b0006d7afc4_REN.png",
    "LRC": "https://assets-currency.kucoin.com/60c745c3db892b0006d819f3_LRC.png",
    "KLV": "https://assets-currency.kucoin.com/60c743d38afb0a00068fe1c2_KLV.png",
    "BOA": "https://assets-currency.kucoin.com/60bf8a008afb0a00068efda7_BOA.png",
    "QNT": "https://assets-currency.kucoin.com/60c3440b8afb0a00068f7711_QNT.png",
    "BAT": "https://assets-currency.kucoin.com/60bf88fadb892b0006d73750_BAT.png",
    "DAO": "https://assets-currency.kucoin.com/60bf8e218afb0a00068efe4b_DAO.png",
    "DOGE": "https://assets-currency.kucoin.com/60bf8fc3db892b0006d73894_DOGE.png",
    "STRONG": "https://assets-currency.kucoin.com/60c353b4db892b0006d7b228_STRONG.png",
    "TRIAS": "https://assets-currency.kucoin.com/60c355e6db892b0006d7b28f_TRIAS.png",
    "MITX": "https://assets-currency.kucoin.com/60c749a5db892b0006d81a88_MITX.png",
    "CAKE": "https://assets-currency.kucoin.com/60bf8b6c8afb0a00068efdd1_CAKE.png",
    "ORAI": "https://assets-currency.kucoin.com/623837d2e5546b00016ca189_MicrosoftTeams-image.png",
    "LTX": "https://assets-currency.kucoin.com/60c746038afb0a00068fe1ec_LTX.png",
    "ZEE": "https://assets-currency.kucoin.com/60c355f5db892b0006d7b294_ZEE.png",
    "MASK": "https://assets-currency.kucoin.com/60c746a1db892b0006d81a14_MASK.png",
    "IDEA": "https://assets-currency.kucoin.com/60c74140db892b0006d8195b_IDEA.png",
    "PHA": "https://assets-currency.kucoin.com/60c2fe8bdb892b0006d7a699_PHA.png",
    "SRK": "https://assets-currency.kucoin.com/60c35605db892b0006d7b295_SRK.png",
    "SWINGBY": "https://assets-currency.kucoin.com/60c356168afb0a00068f7a0c_SWINGBY.png",
    "AVAX": "https://assets-currency.kucoin.com/60bf88c58afb0a00068efd78_AVAX.png",
    "DON": "https://assets-currency.kucoin.com/60bf8fd78afb0a00068efe95_DON.png",
    "KRL": "https://assets-currency.kucoin.com/60c744188afb0a00068fe1c8_KRL.png",
    "POLK": "https://assets-currency.kucoin.com/60c2fe9e8afb0a00068f6dd5_POLK.png",
    "RNDR": "https://assets-currency.kucoin.com/6241aed972efd50001285351_Render_Icon_White-On-Red_400x400px_Round.png",
    "RLY": "https://assets-currency.kucoin.com/60c345b1db892b0006d7afd7_RLY.png",
    "ANC": "https://assets-currency.kucoin.com/60bf88058afb0a00068efd57_ANC.png",
    "SKEY": "https://assets-currency.kucoin.com/60c35627db892b0006d7b298_SKEY.png",
    "LAYER": "https://assets-currency.kucoin.com/60c744888afb0a00068fe1ce_LAYER.png",
    "TARA": "https://assets-currency.kucoin.com/60c35dfb8afb0a00068f7b55_TARA.png",
    "XYM": "https://assets-currency.kucoin.com/60c356328afb0a00068f7a11_XYM.png",
    "DYP": "https://assets-currency.kucoin.com/60bf903a8afb0a00068efeaf_DYP.png",
    "PCX": "https://assets-currency.kucoin.com/60c2feb5db892b0006d7a69a_PCX.png",
    "ORBS": "https://assets-currency.kucoin.com/60d2f0578afb0a000691440f_3835.png",
    "DSLA": "https://assets-currency.kucoin.com/60bf90118afb0a00068efe9d_DSLA.png",
    "FLUX": "https://assets-currency.kucoin.com/60bf92c88afb0a00068eff1f_FLUX.png",
    "SAND": "https://assets-currency.kucoin.com/60c356418afb0a00068f7a18_SAND.png",
    "SPI": "https://assets-currency.kucoin.com/60ec04814916a50006c1d14a_SPI_Token.webp",
    "GSPI": "https://assets-currency.kucoin.com/60ec0383486fc5000613f970_GSPI_Token.webp",
    "XCUR": "https://assets-currency.kucoin.com/60c35e0e8afb0a00068f7b5a_XCUR.png",
    "VAI": "https://assets-currency.kucoin.com/6116313bf115020006046ef6_111-removebg-preview.png",
    "DODO": "https://assets-currency.kucoin.com/60bf8fb3db892b0006d73891_DODO.png",
    "PUNDIX": "https://assets-currency.kucoin.com/60c2fec5db892b0006d7a69f_PUNDIX.png",
    "BOSON": "https://assets-currency.kucoin.com/60bf8a2fdb892b0006d7377c_BOSON.png",
    "HT": "https://assets-currency.kucoin.com/60c03f088afb0a00068f1374_HT.png",
    "PDEX": "https://assets-currency.kucoin.com/60c2fed78afb0a00068f6dd8_PDEX.png",
    "LABS": "https://assets-currency.kucoin.com/60c74467db892b0006d819d0_LABS.png",
    "STRK": "https://assets-currency.kucoin.com/60c356768afb0a00068f7a20_STRK.png",
    "PHNX": "https://assets-currency.kucoin.com/60c2feed8afb0a00068f6ddc_PHNX.png",
    "HAI": "https://assets-currency.kucoin.com/60c03e36db892b0006d74d76_HAI.png",
    "EQZ": "https://assets-currency.kucoin.com/60bf91908afb0a00068efee4_EQZ.png",
    "FORTH": "https://assets-currency.kucoin.com/60bf92ee8afb0a00068eff29_FORTH.png",
    "CARR": "https://assets-currency.kucoin.com/60bf8baadb892b0006d737b8_CARR.png",
    "HORD": "https://assets-currency.kucoin.com/60c03e788afb0a00068f1365_HORD.png",
    "CGG": "https://assets-currency.kucoin.com/60bf8bff8afb0a00068efde9_CGG.png",
    "GHX": "https://assets-currency.kucoin.com/60bf9366db892b0006d73924_GHX.png",
    "TCP": "https://assets-currency.kucoin.com/60c356958afb0a00068f7a25_TCP.png",
    "TOWER": "https://assets-currency.kucoin.com/62b4127c49f4760001f08fb8_towertoken-1000x1000.png",
    "ACE": "https://assets-currency.kucoin.com/62ba65c786bf9a00010f4dc2_3.png",
    "STND": "https://assets-currency.kucoin.com/60c597d7db892b0006d7ec0d_1891623562013_.pic.jpg",
    "LOCG": "https://assets-currency.kucoin.com/60c7452bdb892b0006d819e6_LOCG.png",
    "FLY": "https://assets-currency.kucoin.com/6194aca2b26db3000611c99b_FLY_logo_symbol_%D0%BA%D0%B2%D0%B0%D0%B4%D1%80%D0%B0%D1%82_200_200.png",
    "CARD": "https://assets-currency.kucoin.com/60bf8b9b8afb0a00068efdd7_CARD.png",
    "XDC": "https://assets-currency.kucoin.com/60c360038afb0a00068f7bb7_XDC.png",
    "CWS": "https://assets-currency.kucoin.com/60bf8da38afb0a00068efe37_CWS.png",
    "ADA3S": "https://assets-currency.kucoin.com/6183a1cb8d6d9e00068e6dd2_ada3S.png",
    "FCL": "https://assets-currency.kucoin.com/60bf9267db892b0006d738fc_FCL.png",
    "SHIB": "https://assets-currency.kucoin.com/60c356b18afb0a00068f7a2c_SHIB.png",
    "POLX": "https://assets-currency.kucoin.com/60c2ff098afb0a00068f6de7_POLX.png",
    "KDA": "https://assets-currency.kucoin.com/60c7438b8afb0a00068fe1b6_KDA.png",
    "GOVI": "https://assets-currency.kucoin.com/60bf93afdb892b0006d73931_GOVI.png",
    "ICP": "https://assets-currency.kucoin.com/60c74125db892b0006d81957_ICP.png",
    "CELO": "https://assets-currency.kucoin.com/60bf8bef8afb0a00068efde8_CELO.png",
    "CUSD": "https://assets-currency.kucoin.com/60bf8d6edb892b0006d73817_cUSD.png",
    "STC": "https://assets-currency.kucoin.com/61de4d6bc2e0ff0001f7acbd_MicrosoftTeams-image%20%28154%29.png",
    "MATIC": "https://assets-currency.kucoin.com/60c746b38afb0a00068fe215_MATIC.png",
    "OGN": "https://assets-currency.kucoin.com/60c757ae8afb0a00068fe49d_OGN.png",
    "OUSD": "https://assets-currency.kucoin.com/60c7592fdb892b0006d81d07_OUSD.png",
    "GLQ": "https://assets-currency.kucoin.com/60bf93728afb0a00068eff3c_GLQ.png",
    "TLOS": "https://assets-currency.kucoin.com/611df3a9f115020006c75194_TLOS%20200.png",
    "NIF": "https://assets-currency.kucoin.com/619470ab3b4d8400062c2efa_photo_2021-11-17_11.01.38-removebg-preview.png",
    "YOP": "https://assets-currency.kucoin.com/60c36477db892b0006d7b4f6_YOP.png",
    "MXC": "https://assets-currency.kucoin.com/62b17c7fb46cd60001e54fe4_6287440e15c1ce566364ee5c_mxc-mini-version%20%281%29.svg",
    "ERSDL": "https://assets-currency.kucoin.com/60bf91a78afb0a00068efeea_ERSDL.png",
    "ADA3L": "https://assets-currency.kucoin.com/61921b64b26db3000650a5f1_ada3l.png",
    "HOTCROSS": "https://assets-currency.kucoin.com/60c03ed88afb0a00068f136d_HOTCROSS.png",
    "DPI": "https://assets-currency.kucoin.com/60bf8ff3db892b0006d73897_DPI.png",
    "HYVE": "https://assets-currency.kucoin.com/60c74108db892b0006d81956_HYVE.png",
    "DAPPX": "https://assets-currency.kucoin.com/60c0cc7f8afb0a00068f280b_DAPPX.png",
    "FEAR": "https://assets-currency.kucoin.com/60bf9275db892b0006d73900_FEAR.png",
    "KONO": "https://assets-currency.kucoin.com/60c74404db892b0006d819bf_KONO.png",
    "MAHA": "https://assets-currency.kucoin.com/60c74650db892b0006d81a01_MAHA.png",
    "UNO": "https://assets-currency.kucoin.com/60c362bddb892b0006d7b4ba_UNO.png",
    "PRQ": "https://assets-currency.kucoin.com/60c2ff1ddb892b0006d7a6ae_PRQ.png",
    "PYR": "https://assets-currency.kucoin.com/60c2ff348afb0a00068f6de8_PYR.png",
    "GLCH": "https://assets-currency.kucoin.com/60adbad28afb0a00068c0026_Glitch-logo_blank%20background%20%20copy.png",
    "ALBT": "https://assets-currency.kucoin.com/60bf874edb892b0006d7370b_ALBT.png",
    "XCAD": "https://assets-currency.kucoin.com/60c36064db892b0006d7b42b_XCAD.png",
    "PROM": "https://assets-currency.kucoin.com/62cce0faf06e9c0001948696_MicrosoftTeams-image%20%28229%29.png",
    "EOS3L": "https://assets-currency.kucoin.com/6185f8cb8d6d9e0006008a63_EOS3L.png",
    "EOS3S": "https://assets-currency.kucoin.com/6185fa128d6d9e0006008b06_EOS3S.png",
    "BCH3L": "https://assets-currency.kucoin.com/6185f8d98d6d9e0006008a69_BCH3L.png",
    "BCH3S": "https://assets-currency.kucoin.com/6185fa1e8d6d9e0006008b0d_BCH3S.png",
    "ELON": "https://assets-currency.kucoin.com/60bf90cadb892b0006d738ae_ELON.png",
    "APL": "https://assets-currency.kucoin.com/60bf884fdb892b0006d7372a_APL.png",
    "DIVI": "https://assets-currency.kucoin.com/60bf8f62db892b0006d7388a_DIVI.png",
    "VEED": "https://assets-currency.kucoin.com/60b0b17fdb892b00064dd895_VEED-iconlogo.png",
    "LSS": "https://assets-currency.kucoin.com/60b47fabdb892b0006d57ba5_LSS.png",
    "JUP": "https://assets-currency.kucoin.com/615fe51d8917e40006bd799c_JUP%20LOGO%20500X500.png",
    "AGIX": "https://assets-currency.kucoin.com/60bf87088afb0a00068efd36_AGI.png",
    "DORA": "https://assets-currency.kucoin.com/60b6fc91db892b0006d5e308_8800.png",
    "LPOOL": "https://assets-currency.kucoin.com/60b72cb6db892b0006d5e7fe_LPOOL%20icon.png",
    "VET3L": "https://assets-currency.kucoin.com/618b8287b26db3000643c79d_VET3L.png",
    "VET3S": "https://assets-currency.kucoin.com/618b828fb26db3000643c7aa_VET3s.png",
    "LTC3L": "https://assets-currency.kucoin.com/618b829e3b4d840006da10ad_LTC3L.png",
    "LTC3S": "https://assets-currency.kucoin.com/618b82a7b26db3000643c7bf_LTC3S.png",
    "ETHO": "https://assets-currency.kucoin.com/60b77e44db892b0006d5f62e_ETHOlogo.png",
    "POLS": "https://assets-currency.kucoin.com/60b77ea98afb0a00068dbefc_icon-circle.png",
    "KOK": "https://assets-currency.kucoin.com/60b881568afb0a00068dfaee_KOK%20logo.png",
    "ABBC": "https://assets-currency.kucoin.com/60b88198db892b0006d63218_ABBC%20Logo.png",
    "ZCX": "https://assets-currency.kucoin.com/60bdd03f8afb0a00068ec241_0Aji5pVm.png",
    "ROSN": "https://assets-currency.kucoin.com/60bdd7df8afb0a00068ec307_9783.png",
    "NORD": "https://assets-currency.kucoin.com/60bf0b608afb0a00068eebd0_NORD.png",
    "GMEE": "https://assets-currency.kucoin.com/60bf2267db892b0006d72962_gmee-800x800.png",
    "XAVA": "https://assets-currency.kucoin.com/60c0316d8afb0a00068f11f7_Logo%20%282%29.png",
    "AI": "https://assets-currency.kucoin.com/60c037fedb892b0006d74cda_AI-mark-full-color-rgb-1000px%40300ppi.png",
    "SFUND": "https://assets-currency.kucoin.com/60c072b9db892b0006d753da_Seedify.fund%20256x256%20round-05.png",
    "IOI": "https://assets-currency.kucoin.com/60c0debbdb892b0006d76430_IOI%20logo.png",
    "ALPACA": "https://assets-currency.kucoin.com/60c19353db892b0006d77acc_Alpaca.png",
    "NFT": "https://assets-currency.kucoin.com/60c334c58afb0a00068f749b_NFT.png",
    "MNST": "https://assets-currency.kucoin.com/60c2c6ce8afb0a00068f685f_9679.png",
    "MEM": "https://assets-currency.kucoin.com/6229c30872efd500011aad85_mem_gold_1120x1120.png",
    "DLTA": "https://assets-currency.kucoin.com/60c81c02db892b0006d8366b_logo-DT-square.png",
    "AIOZ": "https://assets-currency.kucoin.com/60c81c5b8afb0a00068ffe84_AIOZ_logo.png",
    "MARSH": "https://assets-currency.kucoin.com/60c822538afb0a00068fff1b_img_circle.png",
    "CQT": "https://assets-currency.kucoin.com/60c89904db892b0006d84743_Covalent_Logomark_Three%20Color_Circle.png",
    "HAPI": "https://assets-currency.kucoin.com/60cafe018afb0a00069069dc_Artboard%208-8.png",
    "GENS": "https://assets-currency.kucoin.com/60cac6ed8afb0a00069063ea_GENS_logo.png",
    "YFDAI": "https://assets-currency.kucoin.com/60cac746db892b0006d89c27_Logo-YFDAI-Icon-Blue%20%282%29.png",
    "FORM": "https://assets-currency.kucoin.com/60cadf46db892b0006d89e9c_FORM.png",
    "MODEFI": "https://assets-currency.kucoin.com/60cb1f2bdb892b0006d8a7c0_modefi_icon_light_bg_250.png",
    "ARRR": "https://assets-currency.kucoin.com/6169383afceb6400072ec32f_111%20%281%29.png",
    "SPHRI": "https://assets-currency.kucoin.com/60d196c7db892b0006d955b4_photo_2021-06-21%2018.52.23.jpeg",
    "CEUR": "https://assets-currency.kucoin.com/60d2d833db892b0006084bb3_cEUR.png",
    "ASD": "https://assets-currency.kucoin.com/60d93794486fc5000611a67d_ASD%E9%BB%91%E8%89%B2.png",
    "EXRD": "https://assets-currency.kucoin.com/60d42ddf486fc5000611330a_EXRD.png",
    "NGM": "https://assets-currency.kucoin.com/61a6e12cac7ba4000623a71e_e-Money-AquaGreen.png",
    "LPT": "https://assets-currency.kucoin.com/60da98694916a50006bfa065_3640.png",
    "STMX": "https://assets-currency.kucoin.com/60dd1b26486fc50006120e7c_STMX.png",
    "BOND": "https://assets-currency.kucoin.com/60de7578486fc500061231df_MicrosoftTeams-image.png",
    "2CRZ": "https://assets-currency.kucoin.com/60e41b724916a50006c0aa92_2Crazy.png",
    "ZORT": "https://assets-currency.kucoin.com/60e549f24916a50006c0d8b1_zortlogo.png",
    "SHFT": "https://assets-currency.kucoin.com/60e67659486fc50006133291_TOKEN-Gradient.png",
    "NEAR": "https://assets-currency.kucoin.com/60e6c45d4916a50006c11714_near_icon.png",
    "OOE": "https://assets-currency.kucoin.com/60eaa166486fc5000613cad3_OOE%20Kucoin%E9%80%82%E9%85%8D%E7%89%88.png",
    "DFYN": "https://assets-currency.kucoin.com/60ebabe8486fc5000613ee01_DFYN%20logo%20final%20RGB-06.png",
    "CFG": "https://assets-currency.kucoin.com/60ee8874486fc50006a14873_CFG%201.jpg",
    "AXC": "https://assets-currency.kucoin.com/60ee8c0f486fc50006a14942_AXIA_Coin_Decal_Blue-01.png",    
    "AXS": "https://assets-currency.kucoin.com/60f0f6ca486fc50006a1a84a_MicrosoftTeams-image.png",
    "CLV": "https://assets-currency.kucoin.com/60f1121c486fc50006a1ac53_photo_2021-07-16_12.58.25-removebg-preview.png",
    "ROUTE": "https://assets-currency.kucoin.com/60f52c9d4916a50006c317fb_Logo_icon.png",
    "KAR": "https://assets-currency.kucoin.com/60f6aba7486fc50006a26794_KAR1.png",
    "BURP": "https://assets-currency.kucoin.com/62132a7372efd5000113f10d_Big%20Town%20Chef%20-%20%24BURP%20-%20Avatar%401x.png",
    "DPET": "https://assets-currency.kucoin.com/61010288cdb62c0006b9317e_Logo%20Game.png",
    "PMON": "https://assets-currency.kucoin.com/61010806dcbfec00068481fb_logo-pmon%402x.png",
    "ERG": "https://assets-currency.kucoin.com/6109fcc6cdb62c0006bed7d1_ERG.png",
    "LITH": "https://assets-currency.kucoin.com/6108e459dcbfec0006859f22_thumbnail.png",
    "SOL": "https://assets-currency.kucoin.com/610924a5dcbfec000685ab54_exchange-white.png",
    "SLP": "https://assets-currency.kucoin.com/616c14da279aeb000627c388_smooth-love-potion.png",
    "XCH": "https://assets-currency.kucoin.com/616c14ec279aeb000627c394_chia_logo.png",
    "HAKA": "https://assets-currency.kucoin.com/62ba774649f4760001f6c932_7.png",
    "MTL": "https://assets-currency.kucoin.com/616c1503fceb6400072fb35e_Metal%20%28MTL%29logo%20.png",
    "GALAX": "https://assets-currency.kucoin.com/61163335ddf8990007f0276d_logo",
    "CIRUS": "https://assets-currency.kucoin.com/6119c092f115020006056edb_cirus%20logo.png",
    "TXA": "https://assets-currency.kucoin.com/62a15e8fb46cd60001da78ba_2022-06-09%2010.43.32.jpg",
    "QI": "https://assets-currency.kucoin.com/620b792072efd5000111d8f1_Benqi%20Token%20Ticker%20White.png",
    "ODDZ": "https://assets-currency.kucoin.com/620647595eef2b0001573a42_img_circle.png",
    "PNT": "https://assets-currency.kucoin.com/611dc966ddf8990007f20959_pTokens-icon-white-eye.png",
    "XPR": "https://assets-currency.kucoin.com/61223cbfddf8990007f31a5d_XPR-Icon-200x200.png",
    "TRIBE": "https://assets-currency.kucoin.com/6125ad65ddf8990007f41964_TRIBE.png",
    "MOVR": "https://assets-currency.kucoin.com/612e0547ddf8990007f5fe00_Moonriver_logo_200x200-removebg-preview.png",
    "MAKI": "https://assets-currency.kucoin.com/612855d7f1150200068aa090_twitterlogo.jpeg",
    "QRDO": "https://assets-currency.kucoin.com/614401b0ddf899000638e894_thumbnail.png",
    "WOO": "https://assets-currency.kucoin.com/612cecc4ddf8990007f5bfe3_61375ee3-369a-463a-ad03-f1d7c834fe8d.jpg",
    "WILD": "https://assets-currency.kucoin.com/62c3d0b70d661a0001c17c06_WechatIMG72.png",
    "SDN": "https://assets-currency.kucoin.com/612ca24eddf8990007f5aa3c_logo.jpg",
    "REP": "https://assets-currency.kucoin.com/612f3ec5f1150200068c2b75_802627E9-2903-41DF-A78A-F28490D5EEB2.png",
    "BNT": "https://assets-currency.kucoin.com/612f4ddcf1150200068c2ff0_ca94e3bb-f955-49e8-9827-ff379e15933e.jpg",
    "OXT": "https://assets-currency.kucoin.com/613f1743f115020006c72501_MicrosoftTeams-image.png",
    "BAL": "https://assets-currency.kucoin.com/61308826f1150200068c805c_f179d0f6-9afa-4faf-b47a-9fd7c3ddaafb.jpg",
    "STORJ": "https://assets-currency.kucoin.com/61309183ddf8990007f69ad3_d24c98f7-1682-417f-b7c9-a09174adcd5d.jpg",
    "YGG": "https://assets-currency.kucoin.com/62ba61e949f4760001f6b65c_6.png",
    "NDAU": "https://assets-currency.kucoin.com/61358c52f1150200068e01c0_NDUA.png",
    "SDAO": "https://assets-currency.kucoin.com/61358e74f1150200068e024a_SDAO.jpg",
    "SKL": "https://assets-currency.kucoin.com/616c1515279aeb000627c3a9_SKLlogo%20svg.png",
    "NMR": "https://assets-currency.kucoin.com/616c1524279aeb000627c3b2_NMR.png",
    "XRP3L": "https://assets-currency.kucoin.com/6185f9018d6d9e0006008a83_xrp3L.png",
    "XRP3S": "https://assets-currency.kucoin.com/6183a7228d6d9e00068e73ab_xrp3s.png",
    "TRB": "https://assets-currency.kucoin.com/61370651ddf899000614e304_spaces_-MDuZIVVaKM65WSDT94C_avatar-1596565095853.png",
    "IXS": "https://assets-currency.kucoin.com/61371eeaddf899000614eb81_IXS%20Helmet%20White.png",
    "PBX": "https://assets-currency.kucoin.com/61693870fceb6400072ec34d_111%20%282%29.png",
    "GTC": "https://assets-currency.kucoin.com/616c1535279aeb000627c3b7_GTC.png",
    "LUNA3L": "https://assets-currency.kucoin.com/6183dc5cdb9c2c0006a8fe54_luna3l.png",
    "LUNA3S": "https://assets-currency.kucoin.com/6185fa5f8d6d9e0006008b29_luna3s.png",
    "DYDX": "https://assets-currency.kucoin.com/6138ba6cddf8990006155f6d_didi.png",
    "EQX": "https://assets-currency.kucoin.com/613975c3f115020006c5a0ca_EQX-Token-Icon-Color.png",
    "RLC": "https://assets-currency.kucoin.com/6139a9e5f115020006c5ab48_token-1%20%281%29.svg",
    "XNL": "https://assets-currency.kucoin.com/613afce3ddf89900066e1592_Chronicle%20Logo-1600x1600-Transparent.png",
    "HBAR": "https://assets-currency.kucoin.com/61405119ddf89900066f832f_111-removebg-preview.png",
    "XPRT": "https://assets-currency.kucoin.com/613eeea8f115020006c7198e_XPRT.png",
    "EGLD": "https://assets-currency.kucoin.com/613eeedcddf89900066f251a_Elrond%20Mark%20Black.png",
    "FLOW": "https://assets-currency.kucoin.com/616c155f279aeb000627c3bf_FLOW.png",
    "DOGE3L": "https://assets-currency.kucoin.com/6185f9368d6d9e0006008a98_doge3l.png",
    "DOGE3S": "https://assets-currency.kucoin.com/6185fa6c8d6d9e0006008b30_doge3s.png",
    "NKN": "https://assets-currency.kucoin.com/616c156f279aeb000627c3cd_NKN%28NKN%291.png",
    "SOL3L": "https://assets-currency.kucoin.com/6183dd03db9c2c0006a8fef2_sol3l.png",
    "SOL3S": "https://assets-currency.kucoin.com/6185f96fdb9c2c0006a7185b_sol3s.png",
    "MLN": "https://assets-currency.kucoin.com/616c157d279aeb000627c3d1_Enzyme%28MLN%29.png",
    "WNCG": "https://assets-currency.kucoin.com/6142f2d8ddf89900063899e0_NCG_CI_2x.png",
    "DMTR": "https://assets-currency.kucoin.com/6295bc6e86bf9a0001c49b46_Dimitra%20icon%20%281%29.png",
    "LINK3L": "https://assets-currency.kucoin.com/6183dda18d6d9e00068eb49f_link3l.png",
    "LINK3S": "https://assets-currency.kucoin.com/6185f97adb9c2c0006a71865_link3s.png",
    "DOT3L": "https://assets-currency.kucoin.com/6183de3b8d6d9e00068eb556_dotl3l.png",
    "DOT3S": "https://assets-currency.kucoin.com/6185f9bedb9c2c0006a7187f_dot3s.png",
    "DINO": "https://assets-currency.kucoin.com/614aa69eddf89900063afaab_dino%20token1.png",
    "CTSI": "https://assets-currency.kucoin.com/616c15a9fceb6400072fb3aa_Cartesi%20%28CTSI%29.png",
    "ALICE": "https://assets-currency.kucoin.com/616c15b6fceb6400072fb3ab_MyNeighborAlice%20%28ALICE%29.png",
    "UMB": "https://assets-currency.kucoin.com/614af627ddf89900063b125b_UMB.png",
    "OPUL": "https://assets-currency.kucoin.com/614af88cf115020007f818f0_MicrosoftTeams-image.png",
    "ILV": "https://assets-currency.kucoin.com/614b3189f115020007f82c8e_MicrosoftTeams-image%20%283%29.png",
    "BAND": "https://assets-currency.kucoin.com/616c15c4279aeb000627c3f0_Band%20Protocol%20%28BAND%29.png",
    "USD": "https://assets-currency.kucoin.com/614c597bc60b2e000678406b_%E7%BE%8E%E5%85%83%E5%9B%BE%E6%A0%87.png",
    "FTT": "https://assets-currency.kucoin.com/616c15d4279aeb000627c3f2_FTT%20logo.png",
    "DVPN": "https://assets-currency.kucoin.com/614d38bc8917e40007187a2f_photo_2021-09-22_21.29.53-removebg-preview.png",
    "SKU": "https://assets-currency.kucoin.com/614fdcfc8917e40007192e43_photo_2021-09-26_09.56.33-removebg-preview.png",
    "SLIM": "https://assets-currency.kucoin.com/61945f403b4d8400062c2674_Solanium-Icon-DarkGradient160x160.png",
    "EDG": "https://assets-currency.kucoin.com/6195c81f3b4d840006d70bd7_photo_2021-11-18%2011.26.57.jpeg",
    "DEXE": "https://assets-currency.kucoin.com/616c15e3fceb6400072fb3ba_DeXe%20%28%20DEXE%29.png",
    "TLM": "https://assets-currency.kucoin.com/616c15f3fceb6400072fb3c3_Alien%20Worlds%20%28TLM%29.png",
    "RMRK": "https://assets-currency.kucoin.com/6151226a8917e40007197af9_rmrk_symbol.png",
    "RUNE": "https://assets-currency.kucoin.com/615167228917e400071988ec_MicrosoftTeams-image%20%281%29.png",
    "MATTER": "https://assets-currency.kucoin.com/6151dae08917e4000719b031_90Matter.png",
    "SOV": "https://assets-currency.kucoin.com/61528cd48917e4000719dbb2_Sovryn_logo_200_200.png",
    "BMON": "https://assets-currency.kucoin.com/61a084a4d09f0b00066cfd0b_logo%20-%2064x64.png",
    "C98": "https://assets-currency.kucoin.com/6153c5c5c60b2e0006335824_MicrosoftTeams-image.png",
    "BLOK": "https://assets-currency.kucoin.com/61551b68c60b2e000633a551_Artboard%2011%20copy.png",
    "SOLR": "https://assets-currency.kucoin.com/615edfbac60b2e0007cd699d_Solrazr-Logomark-Color-500_500.png",
    "UNI3L": "https://assets-currency.kucoin.com/6185f9468d6d9e0006008a9d_uni3l.png",
    "UNI3S": "https://assets-currency.kucoin.com/6185f9d68d6d9e0006008aef_uni3s.png",
    "ATOM3L": "https://assets-currency.kucoin.com/62c39f1d0d661a0001c176c7_MicrosoftTeams-image%20%2823%29.png",
    "ATOM3S": "https://assets-currency.kucoin.com/62c39f2a0d661a0001c176ca_MicrosoftTeams-image%20%2824%29.png",
    "SIENNA": "https://assets-currency.kucoin.com/616938a5279aeb000626d294_111%20%283%29.png",
    "PUSH": "https://assets-currency.kucoin.com/6163f08b8917e40006bf0880_photo_2021-10-09_15-50-30.jpg",
    "WSIENNA": "https://assets-currency.kucoin.com/616938cbfceb6400072ec361_111%20%283%29.png",
    "NTVRK": "https://assets-currency.kucoin.com/6165612d8917e4000658ab84_111.png",
    "YLD": "https://assets-currency.kucoin.com/616528b38917e40006589443_Logo-Yield-Bittrex.png",
    "FLAME": "https://assets-currency.kucoin.com/6167f061c60b2e0006632e4e_IMG_0860.PNG",
    "FTM3L": "https://assets-currency.kucoin.com/6183e005db9c2c0006a90259_ftm3l.png",
    "FTM3S": "https://assets-currency.kucoin.com/6185f9f08d6d9e0006008af6_ftm3s.png",
    "AXS3L": "https://assets-currency.kucoin.com/6183e0a68d6d9e00068eb7e4_axs3l.png",
    "AXS3S": "https://assets-currency.kucoin.com/6185f9fc8d6d9e0006008af9_axs3s.png",
    "AGLD": "https://assets-currency.kucoin.com/6167d6448917e40006598eda_MicrosoftTeams-image%20%281%29.png",
    "NAKA": "https://assets-currency.kucoin.com/6167d4fe8917e40006598e56_2021-10-14%2014.21.20.jpg",
    "REEF": "https://assets-currency.kucoin.com/616d0d81fceb6400073003aa_MicrosoftTeams-image%20%282%29.png",
    "TORN": "https://assets-currency.kucoin.com/616d0e85279aeb000628147e_MicrosoftTeams-image.png",
    "TIDAL": "https://assets-currency.kucoin.com/616e6812279aeb0006288e92_Final%20_%20icon.png",
    "TVK": "https://assets-currency.kucoin.com/616e6ab8fceb64000658d927_MicrosoftTeams-image%20%281%29.png",
    "INJ": "https://assets-currency.kucoin.com/616e6aff279aeb0006288fda_MicrosoftTeams-image%20%283%29.png",
    "NFTB": "https://assets-currency.kucoin.com/616eec1cfceb640006591806_NFTb%20logo.png",
    "MATIC3L": "https://assets-currency.kucoin.com/6183e12ddb9c2c0006a90379_matic3l.png",
    "MATIC3S": "https://assets-currency.kucoin.com/6183e1398d6d9e00068eb874_matic3s.png",
    "BNB3L": "https://assets-currency.kucoin.com/6183e1b8db9c2c0006a90401_bnb3l.png",
    "BNB3S": "https://assets-currency.kucoin.com/6183e1c18d6d9e00068eb92a_bnb3s.png",
    "ALPHA": "https://assets-currency.kucoin.com/6170c547279aeb000629afdf_MicrosoftTeams-image%20%282%29.png",
    "BADGER": "https://assets-currency.kucoin.com/6170d636fceb64000659fb14_MicrosoftTeams-image%20%281%29.png",
    "VEGA": "https://assets-currency.kucoin.com/61728591fceb64000623192a_bw-icon-558px-removebg-preview.png",
    "ZKT": "https://assets-currency.kucoin.com/617655a7279aeb000628fdb4_MicrosoftTeams-image.png",
    "AR": "https://assets-currency.kucoin.com/61765c92fceb64000624a864_AR%20png_%E7%94%BB%E6%9D%BF%201.png",
    "XVS": "https://assets-currency.kucoin.com/617670c8fceb64000624b31f_logo-%282%29.png",
    "GHST": "https://assets-currency.kucoin.com/61779aa7fceb6400068f9df1_ghst-01.png",
    "PERP": "https://assets-currency.kucoin.com/6177b83e279aeb000629d440_logo.png",
    "SCLP": "https://assets-currency.kucoin.com/6177b6e2279aeb000629d363_Logo%20Circle_8x.png",
    "JASMY": "https://assets-currency.kucoin.com/617907c9279aeb000604455f_MicrosoftTeams-image.png",
    "CPOOL": "https://assets-currency.kucoin.com/6179045d279aeb00060442e9_2021-10-27%2015.48.12.jpg",
    "LTO": "https://assets-currency.kucoin.com/6242b90423d4f40001e05801_logo-only-gradient--l_2x.png",
    "SUPER": "https://assets-currency.kucoin.com/6178eb0dfceb6400060e2ac4_logo.png",
    "BASIC": "https://assets-currency.kucoin.com/62c3a91986bf9a0001132b06_MicrosoftTeams-image%20%2826%29.png",
    "AURY": "https://assets-currency.kucoin.com/620b113072efd5000111be10_AURO_Logo.png",
    "HERO": "https://assets-currency.kucoin.com/617a444cdb9c2c0006e1de59_icon200x200.png",
    "XED": "https://assets-currency.kucoin.com/617a581bdb9c2c0006e1ea90_Exeedme_Icon.png",
    "SWASH": "https://assets-currency.kucoin.com/617d53d8db9c2c0006130b69_Swash%20logo%20icon.png",
    "NEAR3L": "https://assets-currency.kucoin.com/6183e2188d6d9e00068eb989_near3l.png",
    "NEAR3S": "https://assets-currency.kucoin.com/6183e22c8d6d9e00068eb997_near3s.png",
    "SUSHI3L": "https://assets-currency.kucoin.com/6183e29a8d6d9e00068eba05_sushi3l.png",
    "SUSHI3S": "https://assets-currency.kucoin.com/6183e2b48d6d9e00068eba2e_sushi3s.png",
    "DREAMS": "https://assets-currency.kucoin.com/617f870c8d6d9e0006245a0e_photo_2021-11-01_14.18.47-removebg-preview.png",
    "MTRG": "https://assets-currency.kucoin.com/617f947b8d6d9e0006246382_MTRG-dark-Blue-rgb-256x256px.png",
    "QUICK": "https://assets-currency.kucoin.com/617f9e36db9c2c000615386b_QuickSwap_logo.png",
    "TRU": "https://assets-currency.kucoin.com/617f9e61db9c2c0006153893_TrueFi%20%28TRU%29_logo.png",
    "WRX": "https://assets-currency.kucoin.com/6180b26fdb9c2c00061647fc_WazirX%20%28WRX%29_logo.png",
    "TKO": "https://assets-currency.kucoin.com/6180f49bdb9c2c00061673dd_Toko%20Token%28TKO%29_logo.png",
    "DATA": "https://assets-currency.kucoin.com/61824761db9c2c0006e6a745_Streamr%20%28DATA%29_logo.png",
    "ISP": "https://assets-currency.kucoin.com/618a5296b26db30006427a3f_MicrosoftTeams-image%20%28119%29.png",
    "CERE": "https://assets-currency.kucoin.com/6188b6a48d6d9e0006331714_b561ffe7-18e5-4470-b9df-bfd0619a1094.png",
    "SHILL": "https://assets-currency.kucoin.com/6188c347db9c2c00077d5408_SHILL%20Token%20Logo.png",
    "HEGIC": "https://assets-currency.kucoin.com/6188dfabdb9c2c00077d73b9_Hegic%20%28HEGIC%29.png",
    "ERN": "https://assets-currency.kucoin.com/6188dfdfdb9c2c00077d73fa_Ethernity%20Chain%20%28ERN%29.png",
    "PAXG": "https://assets-currency.kucoin.com/618a300e3b4d840006d3dcd7_PAX%20Gold%20%28PAXG%29.png",
    "AAVE3L": "https://assets-currency.kucoin.com/618a349db26db30006530362_AAVE3L.png",
    "AUDIO": "https://assets-currency.kucoin.com/618a305db26db3000652fdd0_audius-footer.png",
    "AAVE3S": "https://assets-currency.kucoin.com/618a3495b26db30006530357_aave3s.png",
    "SAND3L": "https://assets-currency.kucoin.com/619dfae96db64a0006665a50_sand3l.png",
    "SAND3S": "https://assets-currency.kucoin.com/619dfaf36db64a0006665a57_SAND3S.png",
    "FTG": "https://assets-currency.kucoin.com/618a31ea3b4d840006d3df1c_FANTOMGO-mark-color-transparent.png",
    "AUD": "https://assets-currency.kucoin.com/618b890f3b4d840006da1945_AUD.png",
    "BGN": "https://assets-currency.kucoin.com/618b891eb26db3000643cfa0_BGN.png",
    "BRL": "https://assets-currency.kucoin.com/618b89313b4d840006da1973_BRL.png",
    "CAD": "https://assets-currency.kucoin.com/618b893f3b4d840006da198f_CAD.png",
    "CHF": "https://assets-currency.kucoin.com/618b894e3b4d840006da19a6_CHF.png",
    "CZK": "https://assets-currency.kucoin.com/618b89623b4d840006da19b4_CZK.png",
    "DKK": "https://assets-currency.kucoin.com/618b8975b26db3000643d00b_DKK.png",
    "EUR": "https://assets-currency.kucoin.com/618b89853b4d840006da19dd_EUR.png",
    "GBP": "https://assets-currency.kucoin.com/618b89a23b4d840006da1a0a_GBP.png",
    "HKD": "https://assets-currency.kucoin.com/618b89b8b26db3000643d059_HKD.png",
    "HRK": "https://assets-currency.kucoin.com/618b89c9b26db3000643d074_HRK.png",
    "INR": "https://assets-currency.kucoin.com/618b89e2b26db3000643d097_INR.png",
    "JPY": "https://assets-currency.kucoin.com/618b89f6b26db3000643d0b0_JPY.png",
    "KZT": "https://assets-currency.kucoin.com/618b8aa03b4d840006da1b79_KZT.png",
    "MXN": "https://assets-currency.kucoin.com/618b8adb3b4d840006da1bc1_MXN.png",
    "MYR": "https://assets-currency.kucoin.com/618b8aec3b4d840006da1bd6_MYR.png",
    "NGN": "https://assets-currency.kucoin.com/618b8afe3b4d840006da1bfc_NGN.png",
    "PLN": "https://assets-currency.kucoin.com/618b8b14b26db3000643d1dd_PLN.png",
    "RON": "https://assets-currency.kucoin.com/618b8b2bb26db3000643d1f0_RON.png",
    "RUB": "https://assets-currency.kucoin.com/618b8b433b4d840006da1c50_RUB.png",
    "SEK": "https://assets-currency.kucoin.com/618b8b55b26db3000643d23c_SEK.png",
    "SGD": "https://assets-currency.kucoin.com/618b8b683b4d840006da1c9a_SGD.png",
    "THB": "https://assets-currency.kucoin.com/618b8b7eb26db3000643d28a_THB.png",
    "TWD": "https://assets-currency.kucoin.com/618b8b9eb26db3000643d2c8_TWD.png",
    "UAH": "https://assets-currency.kucoin.com/618b8badb26db3000643d2de_UAH.png",
    "ZAR": "https://assets-currency.kucoin.com/618b8bbd3b4d840006da1d04_ZAR.png",
    "XTM": "https://assets-currency.kucoin.com/618b35bc3b4d840006d9c709_XTM.png",
    "ENS": "https://assets-currency.kucoin.com/618b449f3b4d840006d9d450_%E4%B8%8B%E8%BD%BD.png",
    "ATA": "https://assets-currency.kucoin.com/618b567db26db30006439ae9_Automata%20Network%20%28ATA%29.png",
    "FXS": "https://assets-currency.kucoin.com/618b56c5b26db30006439b30_fraxlogo.png",
    "MNW": "https://assets-currency.kucoin.com/618b602e3b4d840006d9eba6_MicrosoftTeams-image.png",
    "CWAR": "https://assets-currency.kucoin.com/618bc5483b4d840006da777f_icon-transparent.png",
    "VXV": "https://assets-currency.kucoin.com/618cac663b4d840006b1b790_Hex_whitebg.png",
    "DPR": "https://assets-currency.kucoin.com/618cb0d63b4d840006b1bb4c_deeper%20logo%20Twitter.png",
    "SWP": "https://assets-currency.kucoin.com/618e22bab26db30006b50003_Kava-Swap-Coin-256px.png",
    "PBR": "https://assets-currency.kucoin.com/6191fa41b26db3000650854d_symbol_500x500.png",
    "WNXM": "https://assets-currency.kucoin.com/6192062ab26db300065090a4_MicrosoftTeams-image%20%282%29.png",
    "ANT": "https://assets-currency.kucoin.com/6192234f3b4d8400067d3018_MicrosoftTeams-image%20%281%29.png",
    "ADX": "https://assets-currency.kucoin.com/61933b163b4d8400062b125f_photo_2021-11-15%2009.38.20.jpeg",
    "TWT": "https://assets-currency.kucoin.com/61935ceab26db30006da85fa_MicrosoftTeams-image%20%281%29.png",
    "OM": "https://assets-currency.kucoin.com/61936556b26db30006da8d35_logo%20%281%29.png",
    "AVAX3L": "https://assets-currency.kucoin.com/619464fdb26db3000611a447_AVAX3L.png",
    "AVAX3S": "https://assets-currency.kucoin.com/619464f63b4d8400062c296f_AVAX3S.png",
    "MANA3L": "https://assets-currency.kucoin.com/619464943b4d8400062c292a_MANA3L.png",
    "MANA3S": "https://assets-currency.kucoin.com/619464893b4d8400062c291e_MANA3S.png",
    "GLM": "https://assets-currency.kucoin.com/6194ab133b4d8400062c4c3c_logo.png",
    "NUM": "https://assets-currency.kucoin.com/6194ad3e3b4d8400062c4d84_NUM%20icon-200x200.png",
    "BAKE": "https://assets-currency.kucoin.com/6194b3ad3b4d8400062c5289_logo%20%281%29.png",
    "MONI": "https://assets-currency.kucoin.com/6195ce4b3b4d840006d70ffd_Moni-Coin-Logo.png",
    "TRADE": "https://assets-currency.kucoin.com/6195fca3b26db3000612f894_Color.png",
    "VLX": "https://assets-currency.kucoin.com/6195ff43b26db3000612faf1_velas%20symbol%20color%201.png",
    "1EARTH": "https://assets-currency.kucoin.com/6196181fb26db300061312bd_Logo%20color.png",
    "KAVA": "https://assets-currency.kucoin.com/619b03c13b4d84000659d1c4_Kava-Token-256%20%281%29.png",
    "LIKE": "https://assets-currency.kucoin.com/619b317eb26db30006722cbe_LIKE%20logo.png",
    "LIT": "https://assets-currency.kucoin.com/619b31d4b26db30006722d04_LIT.png",
    "MFT": "https://assets-currency.kucoin.com/619b31f3b26db30006722d1a_Hifi%20Finance%20%28MFT%29%20.png",
    "SFP": "https://assets-currency.kucoin.com/619c880ac6f62c0006c8c15e_SFP.png",
    "BURGER": "https://assets-currency.kucoin.com/619cb04e6db64a0006b4ee43_BURGER.png",
    "ILA": "https://assets-currency.kucoin.com/619de7d8c6f62c000695b7ab_ILA.png",
    "CREAM": "https://assets-currency.kucoin.com/619e0a2dc6f62c000695d8bb_cream%20logo.png",
    "RSR": "https://assets-currency.kucoin.com/619e10686db64a000666714c_RSR.png",
    "GODS": "https://assets-currency.kucoin.com/619f2cedc6f62c000697060d_logo--gods-unchained-icon.png",
    "IMX": "https://assets-currency.kucoin.com/61a03f0eac7ba40006205758_IMX_colour_400x400.png",
    "KMA": "https://assets-currency.kucoin.com/62ba6180b46cd60001edbf67_4.png",
    "SRM": "https://assets-currency.kucoin.com/61a48ad6d09f0b00066ebf4d_Serum%20%28SRM%29%20token.png",
    "POLC": "https://assets-currency.kucoin.com/61a4906eac7ba400062237e6_photo_2021-11-29_10.16.35-removebg-preview.png",
    "KIN": "https://assets-currency.kucoin.com/61a4a3a6c3456600064a6dae_kin_icon_160_160_Purple.png",
    "XTAG": "https://assets-currency.kucoin.com/61a4ab464eabd90006e4aa73_xHashtag%20%28XTAG%29.png",
    "VR": "https://assets-currency.kucoin.com/61ad73b87d2d040006315bba_MicrosoftTeams-image.png",
    "MNET": "https://assets-currency.kucoin.com/61a5c1254eabd90006e55c69_MINE_Network_logo-final-06.png",
    "NGC": "https://assets-currency.kucoin.com/61a5c6224eabd90006e55e6c_Coin%20icon%20%281%29.png",
    "GALAX3L": "https://assets-currency.kucoin.com/61a72a3ac3456600064bec70_galax3l.png",
    "GALAX3S": "https://assets-currency.kucoin.com/61a72a444eabd90006e61f14_galax3s.png",
    "HARD": "https://assets-currency.kucoin.com/61a5e17c4eabd90006e56bcc_Kava-Lend-Token-256px%20%281%29.png",
    "UNIC": "https://assets-currency.kucoin.com/61a70e274eabd90006e61308_MicrosoftTeams-image%20%283%29.png",
    "POND": "https://assets-currency.kucoin.com/61a715134eabd90006e615f4_marlin%20Token%20logo.png",
    "MDX": "https://assets-currency.kucoin.com/61a715a5d09f0b0006704632_Mdex%20%28MDX%29.png",
    "EPIK": "https://assets-currency.kucoin.com/61a840604eabd90006e6c2b2_epik%20logo_icon%28cmyk%29.png",
    "NGL": "https://assets-currency.kucoin.com/61a9ca2d6e4f1d0006bc64dc_10265-removebg-preview.png",
    "KDON": "https://assets-currency.kucoin.com/61a9b5f16e4f1d0006bc5d62_DON%20Logo%202.png",
    "PEL": "https://assets-currency.kucoin.com/61adda6d37b6c5000676c2d0_glyph-2x.png",
    "KLAY": "https://assets-currency.kucoin.com/61aee2807d2d04000631f222_Klaytn-%28KLAY%29logo.png",
    "LINA": "https://assets-currency.kucoin.com/61af010a7d2d04000631fa37_Linear-%28LINA%29logo.png",
    "CREDI": "https://assets-currency.kucoin.com/61af34886e4f1d0006d5d400_logo-icon.png",
    "TRVL": "https://assets-currency.kucoin.com/625e618d23d4f40001ee0293_TRVL%20new.jpg",
    "ARKER": "https://assets-currency.kucoin.com/61b0879d6e4f1d0006e8bc71_arker.png",
    "XEC": "https://assets-currency.kucoin.com/61b0920137b6c5000677de92_10791.png",
    "BONDLY": "https://assets-currency.kucoin.com/62b1377b49f4760001ee19ef_iShot_2022-06-21_11.png",
    "LACE": "https://assets-currency.kucoin.com/61b1b72b6e4f1d0006e93a9e_Coin%20logo.png",
    "HEART": "https://assets-currency.kucoin.com/61b55b1d37b6c5000602f54f_h%20logo%401x%20%281%29.png",
    "UNB": "https://assets-currency.kucoin.com/61b6f6596e4f1d0006eb109d_Unbound%20icon-01.jpg",
    "H3RO3S": "https://assets-currency.kucoin.com/61b833dab0aeb8000657becd_Asset%204%402x.png",
    "FORWARD": "https://assets-currency.kucoin.com/61b83cc66e4f1d0006eb82c5_Asset%203%404x.png",
    "GAFI": "https://assets-currency.kucoin.com/61b83edeb0aeb8000657c1c9_GameFi%20Icon_Circle.png",
    "KOL": "https://assets-currency.kucoin.com/61b9b3f137b6c500062f1d1b_12196.png",
    "CHMB": "https://assets-currency.kucoin.com/62c3a1c949f4760001fa9be5_MicrosoftTeams-image%20%2827%29.png",
    "FALCONS": "https://assets-currency.kucoin.com/61bc4faa7d2d04000649dbec_IMG_5666.PNG",
    "UFO": "https://assets-currency.kucoin.com/62c3d0600d661a0001c17bf1_WechatIMG69.png",
    "GEEQ": "https://assets-currency.kucoin.com/61bfe85c37b6c50006773a05_Geeq-Logo-Classic-Geeq-Blue.png",
    "RACEFI": "https://assets-currency.kucoin.com/61c15ab86e4f1d0006fcfcd2_MicrosoftTeams-image%20%289%29.png",
    "ORC": "https://assets-currency.kucoin.com/61c16b566e4f1d0006fd0023_orbit_ORC.png",
    "PEOPLE": "https://assets-currency.kucoin.com/61c291f07d2d0400064b9bb8_photo_2021-12-21%2017.12.45.jpeg",
    "ADS": "https://assets-currency.kucoin.com/61c2dff8b0aeb800065b05fc_LOGO%20PNG.png",
    "OOKI": "https://assets-currency.kucoin.com/61c42fda6e4f1d000692db1b_OokiToken.png",
    "SOS": "https://assets-currency.kucoin.com/61c6facd6e4f1d000693c599_1111.png",
    "WHALE": "https://assets-currency.kucoin.com/61c972157d2d04000670e370_WechatIMG136.png",
    "IOTA": "https://assets-currency.kucoin.com/61cabb0bb0aeb80006cc21f4_IOTA.png",
    "CWEB": "https://assets-currency.kucoin.com/61cc32676e4f1d000695b030_Logo_Colour%404x.png",
    "HNT": "https://assets-currency.kucoin.com/61d3b536b0aeb80006f92bd7_Helium%20%28HNT%29%20.png",
    "GGG": "https://assets-currency.kucoin.com/61d3f569b0aeb80006f94266_Logo.jpg",
    "CLH": "https://assets-currency.kucoin.com/61d472bdb0aeb80006f9a886_photo_2021-12-30%2000.56.55.jpeg",
    "REVU": "https://assets-currency.kucoin.com/621ed3a9e5546b0001645a5e_200x200%20_%20krug%20sredina.png",
    "PLGR": "https://assets-currency.kucoin.com/61dbf8d60e88bf0001bb699f_MicrosoftTeams-image%20%282%29.png",
    "GLMR": "https://assets-currency.kucoin.com/61dd275e11dda800011f3eaf_Moonbeam200x200%20%281%29.png",
    "CTC": "https://assets-currency.kucoin.com/61e0d5b3e5546b0001503e62_creditcoin-%28CTC%29-logo.png",
    "LOVE": "https://assets-currency.kucoin.com/62c251050d661a0001c13671_200_200.png",
    "GARI": "https://assets-currency.kucoin.com/61e5220c72efd50001e05cdb_Gari%20Icon.png",
    "FRR": "https://assets-currency.kucoin.com/61e67e7323d4f400018dabc2_token%20logo.png",
    "ASTR": "https://assets-currency.kucoin.com/61e7c062e5546b0001533bb1_ring.png",
    "ERTHA": "https://assets-currency.kucoin.com/61e7cd4923d4f400018e3aa9_Ertha_token%402x.png",
    "FCON": "https://assets-currency.kucoin.com/61ee243372efd50001e41277_LOGO%20200x200%20%281%29.png",
    "ACA": "https://assets-currency.kucoin.com/61ee8c88e5546b000155ec29_ACA%20Token%20PNG.png",
    "MTS": "https://assets-currency.kucoin.com/61efac35e5546b0001566efe_MTS-coin.png",
    "ROAR": "https://assets-currency.kucoin.com/61f1198172efd5000109e2bf_MicrosoftTeams-image%20%28160%29.png",
    "HBB": "https://assets-currency.kucoin.com/61f24e1872efd500010a6650_HBB%20icon%20round.png",
    "SURV": "https://assets-currency.kucoin.com/61f253fde5546b0001579b6c_SURV%20logo.png",
    "AMP": "https://assets-currency.kucoin.com/61f8cfc423d4f40001c4438b_6945.png",
    "CVX": "https://assets-currency.kucoin.com/61f8cff9e5546b000159db44_cvx.png",
    "MJT": "https://assets-currency.kucoin.com/62b97f5486bf9a00010e66b1_6204addee5546b00015d3473_MicrosoftTeams-image.png",
    "XNO": "https://assets-currency.kucoin.com/6205c13272efd500011062d7_MicrosoftTeams-image.png",
    "SHX": "https://assets-currency.kucoin.com/62183de723d4f40001cd0d2d_Stronghold%20Logo%20Mark%20Blue.png",
    "STARLY": "https://assets-currency.kucoin.com/620c60a05eef2b000158db37_starly-token-200x200.png",
    "ONSTON": "https://assets-currency.kucoin.com/620dbb0023d4f40001ca198b_1.token%20logo_200.png",
    "WMT": "https://assets-currency.kucoin.com/6241bd5e72efd50001285bf8_Mark.png",
    "RANKER": "https://assets-currency.kucoin.com/6214b65c23d4f40001cbfdf5_%24RANKER%20Coin%20for%20CMC_GECKO.png",
    "LAVAX": "https://assets-currency.kucoin.com/6216fe2f5eef2b00015bcca8_image0.jpeg",
    "MARS4": "https://assets-currency.kucoin.com/62189d9ee5546b000162c012_photo_2022-02-23%2011.05.42.png",
    "METIS": "https://assets-currency.kucoin.com/621f18f25eef2b00015dfcfd_WhiteGreen%403x.png",
    "WAL": "https://assets-currency.kucoin.com/6221f25d23d4f40001cfc6b5_Coin_WAL.png",
    "BULL": "https://assets-currency.kucoin.com/6226e74b5eef2b0001607197_Bull_Circle_PNG.png",
    "SON": "https://assets-currency.kucoin.com/6230043223d4f40001d45883_%E6%9C%AA%E5%91%BD%E5%90%8D_%E8%87%AA%E5%AE%9A%E4%B9%89px_2022-03-15%2B11_12_30.png",
    "MELOS": "https://assets-currency.kucoin.com/623841d472efd500011f7534_MicrosoftTeams-image__17_-removebg-preview.png",
    "APE": "https://assets-currency.kucoin.com/6232c551e5546b00016ad016_logo_%E7%94%BB%E6%9D%BF%201.png",
    "GMT": "https://assets-currency.kucoin.com/6233f8f623d4f40001d5a347_GMT.png",
    "NOK": "https://assets-currency.kucoin.com/623aba815eef2b0001694d6c_NOK.png",
    "SAR": "https://assets-currency.kucoin.com/623abaae5eef2b0001694dfd_SAR.png",
    "AED": "https://assets-currency.kucoin.com/623abba572efd50001229118_AED.png",
    "ILS": "https://assets-currency.kucoin.com/623abbc723d4f40001da2d94_ILS.png",
    "NZD": "https://assets-currency.kucoin.com/623abbe623d4f40001da2dec_NZD.png",
    "PHP": "https://assets-currency.kucoin.com/623abbfd72efd50001229223_PHP.png",
    "COP": "https://assets-currency.kucoin.com/623abc6f5eef2b0001695354_COP.png",
    "IDR": "https://assets-currency.kucoin.com/623abc8f5eef2b00016953bb_IDR.png",
    "VND": "https://assets-currency.kucoin.com/623abcac72efd50001229443_VND.png",
    "KRW": "https://assets-currency.kucoin.com/623abcc223d4f40001da30c6_KRW.png",
    "ARS": "https://assets-currency.kucoin.com/623abcdce5546b00016fc344_ARS.png",
    "DZD": "https://assets-currency.kucoin.com/623abcf45eef2b00016954eb_DZD.png",
    "BICO": "https://assets-currency.kucoin.com/623c19d1e5546b0001732e3d_BICO.jpeg",
    "STG": "https://assets-currency.kucoin.com/623d3b88e5546b000173fdc6_STG.png",
    "LMR": "https://assets-currency.kucoin.com/623db895e5546b000174283e_Lumerin200x200.png",
    "LOKA": "https://assets-currency.kucoin.com/6241635c23d4f40001dfc7be_MicrosoftTeams-image%20%2818%29.png",
    "URUS": "https://assets-currency.kucoin.com/6242d47d72efd5000128c827_MicrosoftTeams-image%20%281%29.png",
    "BNC": "https://assets-currency.kucoin.com/6243c8e272efd50001293e84_MicrosoftTeams-image%20%2818%29.png",
    "JAM": "https://assets-currency.kucoin.com/624406695eef2b000170193c_Geojam%20Token%20Logo_200x200_TPT.png",
    "LBP": "https://assets-currency.kucoin.com/62443ee9e5546b000176a222_000.png",
    "CFX": "https://assets-currency.kucoin.com/62451b0be5546b0001770361_Conflux%20%28CFX%29.png",
    "XCN": "https://assets-currency.kucoin.com/624657bf5eef2b00017132ca_icon.png",
    "KP3R": "https://assets-currency.kucoin.com/6246bdd023d4f40001e238a4_2-02.png",
    "LOOKS": "https://assets-currency.kucoin.com/6246a93023d4f40001e22b46_looks-black%40512.png",
    "UPO": "https://assets-currency.kucoin.com/62481bd672efd500012b47e0_icon.png",
    "INDI": "https://assets-currency.kucoin.com/62b97fe886bf9a00010e6770_624bb525ffe3fe000143fc68_IGG%20Token%20Icon.png",
    "TITAN": "https://assets-currency.kucoin.com/624c3daae5546b00017a8643_logotitan1.png",
    "SPELL": "https://assets-currency.kucoin.com/624d48a5e5546b00017b0bbb_MicrosoftTeams-image%20%2819%29.png",
    "SLCL": "https://assets-currency.kucoin.com/624d77a072efd500012e03c1_SLCL%20token%20logo%20%281%29.png",
    "RPC": "https://assets-currency.kucoin.com/624e429cffe3fe0001456cb8_01_rpc-icon.png",
    "CEEK": "https://assets-currency.kucoin.com/624e96eb23d4f40001e63a52_logo.png",
    "T": "https://assets-currency.kucoin.com/624ea7f272efd500012ea5a0_Threshold%20%28T%29.png",
    "BETA": "https://assets-currency.kucoin.com/624eace3e5546b00017bd813_11307.png",
    "VEMP": "https://assets-currency.kucoin.com/624eadd2ffe3fe000145b651_logo.png",
    "NHCT": "https://assets-currency.kucoin.com/624fd8f9e5546b00017c915d_NHCT%20icon%403x.png",    
    "FRA": "https://assets-currency.kucoin.com/6253c85923d4f40001e9b03a_Findora_symbol2%402x.png",
    "ARNM": "https://assets-currency.kucoin.com/62c39fc8b46cd60001f1a3f1_MicrosoftTeams-image%20%2822%29.png",
    "VISION": "https://assets-currency.kucoin.com/62551cd7e5546b0001802b4d_bg-black-logo%20%281%29.png",
    "ALPINE": "https://assets-currency.kucoin.com/62552000ffe3fe00014a07b8_18112.png",
    "COCOS": "https://assets-currency.kucoin.com/6255636b23d4f40001ead683_Cocos-BCX_002_PNG-removebg-preview.png",
    "BNX": "https://assets-currency.kucoin.com/62567943ffe3fe00014acaf1_MicrosoftTeams-image%20%2819%29.png",
    "ZBC": "https://assets-currency.kucoin.com/62ba620886bf9a00010f4a87_5.png",
    "WOOP": "https://assets-currency.kucoin.com/6257d155e5546b000181884e_logo-woop-circulo%20200x200.png",
    "NYM": "https://assets-currency.kucoin.com/62591653ffe3fe00014bc800_MicrosoftTeams-image.png",
    "VOXEL": "https://assets-currency.kucoin.com/62c3d37286bf9a0001132fdc_WechatIMG71.png",
    "PSTAKE": "https://assets-currency.kucoin.com/625d350723d4f40001eda1ba_PSTAKE_Dark.png",
    "SPA": "https://assets-currency.kucoin.com/625d5191ffe3fe00014d130c_Logo_Sperax.png",
    "RACA": "https://assets-currency.kucoin.com/625e158272efd50001364e17_200x200%20logo%20.png",
    "DAR": "https://assets-currency.kucoin.com/625e166c23d4f40001edf024_icon%20logo.png",
    "SYNR": "https://assets-currency.kucoin.com/625e63a7e5546b0001838dbb_MicrosoftTeams-image%20%2819%29.png",
    "MV": "https://assets-currency.kucoin.com/625e666ee5546b0001838e64_MV.png",
    "XDEFI": "https://assets-currency.kucoin.com/625fd650ffe3fe00014de0ad_XDEFI%20Wallet%20%28XDEFI%29.png",
    "HAWK": "https://assets-currency.kucoin.com/625f7dcfffe3fe00014dc45f_fb24f856-596e-45bb-b1b2-20c356a1c235.png",
    "SWFTC": "https://assets-currency.kucoin.com/62c3d0780d661a0001c17bf4_WechatIMG73.png",
    "XWG": "https://assets-currency.kucoin.com/625fd08423d4f40001ee7770_circle-black.png",
    "IDEX": "https://assets-currency.kucoin.com/6260d83172efd50001373584_IDEX_LOGO.png",
    "PLATO": "https://assets-currency.kucoin.com/6260f660e5546b000184699a_PLATO%20200.png",
    "BRWL": "https://assets-currency.kucoin.com/62c39edd86bf9a00011329e4_MicrosoftTeams-image%20%2821%29.png",
    "TAUM": "https://assets-currency.kucoin.com/6262671fe5546b000184eac7__Token%20TAUM%20final.png",
    "CELR": "https://assets-currency.kucoin.com/6264e895e5546b000185a294_3814.png",
    "ITAMCUBE": "https://assets-currency.kucoin.com/6264f0cc23d4f40001f015e3_G-nQqjnC_400x400.png",
    "AURORA": "https://assets-currency.kucoin.com/626507f4e5546b000185aa77_aurora-stack.png",    
    "POSI": "https://assets-currency.kucoin.com/62c39ef0b46cd60001f1a3dc_MicrosoftTeams-image%20%2820%29.png",
    "SIN": "https://assets-currency.kucoin.com/6266442972efd5000138d0e8_Sin%20Verse%20Logo%20White%20Text%20Transparent%20Windows.png",
    "COOHA": "https://assets-currency.kucoin.com/62664cfc72efd5000138d3d3_COOHA.png",
    "EPK": "https://assets-currency.kucoin.com/62677ccbffe3fe0001503984_IMAGE%202022-04-26%2013%3A01%3A52.jpg",
    "PLD": "https://assets-currency.kucoin.com/626794f472efd50001393668_logo-pld_200.png",
    "EPX": "https://assets-currency.kucoin.com/6267b4c872efd500013940bd_MicrosoftTeams-image.png",
    "PSL": "https://assets-currency.kucoin.com/62ba680c49f4760001f6bbab_8.png",
    "SYS": "https://assets-currency.kucoin.com/6268e23423d4f40001f140db_syscoin-blue-flat-logo%404x.png",
    "OVR": "https://assets-currency.kucoin.com/6268ec1be5546b000186d383_01OVRlogo-01.png",
    "PKF": "https://assets-currency.kucoin.com/6268f104e5546b000186d4e1_PolkaFoundry%20-%20Token%20Icon%20200x200.png",
    "DG": "https://assets-currency.kucoin.com/62bd44c249f4760001f97b04_DG%20LOGO%20UPDATED.png",
    "BRISE": "https://assets-currency.kucoin.com/626a32e623d4f40001f1a4fd_11079.png",
    "PLY": "https://assets-currency.kucoin.com/626a388923d4f40001f1a689_token%20icon.png",
    "GST": "https://assets-currency.kucoin.com/626a638123d4f40001f1b29f_16352.png",
    "AKT": "https://assets-currency.kucoin.com/626b5bfe23d4f40001f1fb31_7431.png",
    "FSN": "https://assets-currency.kucoin.com/6273436a23d4f40001f43aac_fusion-token-logo.svg",
    "GAL": "https://assets-currency.kucoin.com/62734b53e5546b000189ca32_logo.png",
    "FITFI": "https://assets-currency.kucoin.com/6273656872efd500013c9a48_logo200x200.png",
    "BSW": "https://assets-currency.kucoin.com/62738bc6ffe3fe000153aafd_Biswap%20200x200.png",
    "H2O": "https://assets-currency.kucoin.com/6273976c72efd500013caa08_H2O-logo.png",
    "AUSD": "https://assets-currency.kucoin.com/62ba60c549f4760001f6b561_1.png",
    "GMM": "https://assets-currency.kucoin.com/62749a4872efd500013cf730_Gamium_coinmarketcap.png",
    "FCD": "https://assets-currency.kucoin.com/6274f645ffe3fe0001541478_FCD_200.png",
    "BOBA": "https://assets-currency.kucoin.com/6278c326ffe3fe000154f49d_14556.png",    
    "BFC": "https://assets-currency.kucoin.com/6279d4d3ffe3fe000155606d_BFC_Token_Logo.png",
    "BIFI": "https://assets-currency.kucoin.com/627a090623d4f40001f612bd_BiFi_Token_Logo.png",
    "KARA": "https://assets-currency.kucoin.com/627a2dcd23d4f40001f62041_MicrosoftTeams-image.png",
    "DFA": "https://assets-currency.kucoin.com/627a3b5323d4f40001f6261c_dfa.png",
    "KYL": "https://assets-currency.kucoin.com/627b638b72efd500013edace_Kylin-logo-6.jpg",
    "CELT": "https://assets-currency.kucoin.com/62c5121f49f4760001fad82d_output-160X160.png",
    "MBL": "https://assets-currency.kucoin.com/627ccd4323d4f40001f742da_MBL.png",
    "DUSK": "https://assets-currency.kucoin.com/627cd871ffe3fe000156a16f_Token%20logo%202.jpg",
    "CCD": "https://assets-currency.kucoin.com/62909f7ab46cd60001d0f29f_18031__1_-removebg-preview.png",
    "USDD": "https://assets-currency.kucoin.com/62837496b46cd6000199ee77_MicrosoftTeams-image%20%28211%29.png",
    "MBOX": "https://assets-currency.kucoin.com/628469c849f4760001a3bd5d_MBOX%20Token200.png",
    "SCRT": "https://assets-currency.kucoin.com/6285df6e86bf9a00018c1993_secret-scrt-logo.png",
    "MLS": "https://assets-currency.kucoin.com/628f429db46cd60001cd8bb1_token%20logo.png",
    "AFK": "https://assets-currency.kucoin.com/628b56e90d661a00018cd762_AFK.jpg",
    "ACH": "https://assets-currency.kucoin.com/628c42fa49f4760001cf76de_6958.png",
    "APE3L": "https://assets-currency.kucoin.com/6232c551e5546b00016ad016_logo_%E7%94%BB%E6%9D%BF%201.png",
    "APE3S": "https://assets-currency.kucoin.com/6232c551e5546b00016ad016_logo_%E7%94%BB%E6%9D%BF%201.png",
    "GMT3L": "https://assets-currency.kucoin.com/6233f8f623d4f40001d5a347_GMT.png",
    "GMT3S": "https://assets-currency.kucoin.com/6233f8f623d4f40001d5a347_GMT.png",
    "IHC": "https://assets-currency.kucoin.com/62a3179bb46cd60001db7627_MicrosoftTeams-image.png",
    "STORE": "https://assets-currency.kucoin.com/628dfaee49f4760001d38b96_vOBhqhSk_400x400.jpeg",
    "DOSE": "https://assets-currency.kucoin.com/628dfde549f4760001d392e7_DOSE_Symbol.png",
    "LUNC": "https://assets-currency.kucoin.com/628f799649f4760001d732f6_01_LunaClassic_color.png",
    "USTC": "https://assets-currency.kucoin.com/628f79db86bf9a0001bcff2e_UST.png",
    "JASMY3L": "https://assets-currency.kucoin.com/617907c9279aeb000604455f_MicrosoftTeams-image.png",
    "JASMY3S": "https://assets-currency.kucoin.com/617907c9279aeb000604455f_MicrosoftTeams-image.png",
    "SRBS": "https://assets-currency.kucoin.com/6295ae88b46cd60001d5ac25_SRBS%20160.png",
    "RBS": "https://assets-currency.kucoin.com/6295aea8b46cd60001d5ac44_RBS160.png",    
    "OP": "https://assets-currency.kucoin.com/62961e34b46cd60001d628e0_MicrosoftTeams-image.png",
    "EVER": "https://assets-currency.kucoin.com/62970ab286bf9a0001c5e856_logo.png",
    "ICX": "https://assets-currency.kucoin.com/6298824286bf9a0001c670d1_2099.png",
    "MOOV": "https://assets-currency.kucoin.com/629dc85786bf9a0001c80d0f_dotmoovs-symbol-gradient.png",
    "USDP": "https://assets-currency.kucoin.com/62c3d15d49f4760001faa122_WechatIMG70.png",
    "WELL": "https://assets-currency.kucoin.com/62b2bd0f0d661a0001b640eb_artemis.png",
    "CSPR": "https://assets-currency.kucoin.com/62b2e3a449f4760001ef86fd_casper-token%20-%20logo-symbol-red.png",
    "FORT": "https://assets-currency.kucoin.com/62b4227286bf9a0001d67c1c_Forta%20%28FORT%29-logo%20.png",
    "REV3L": "https://assets-currency.kucoin.com/62bbb7d449f4760001f804df_REV3L%20-%20COLOR%20%20ICON%20200X200.png",
    "WEMIX": "https://assets-currency.kucoin.com/62bc117a49f4760001f85955_wemix.png",
    "OGV": "https://assets-currency.kucoin.com/62be5c1149f4760001f9b570_MicrosoftTeams-image.png",
    "OLE": "https://assets-currency.kucoin.com/62c40e5749f4760001faaaa4_logo-200x200.png",
    "LDO": "https://assets-currency.kucoin.com/62c654150d661a0001c1e90a_LDO_token.png",
    "CULT": "https://assets-currency.kucoin.com/62c7d0ea0d661a0001c22480_MicrosoftTeams-image.png",
    "VSYS": "https://assets-currency.kucoin.com/60c359cc8afb0a00068f7ab3_VSYS.png",
    "ACT": "https://assets-currency.kucoin.com/60bf8672db892b0006d736f1_ACT.png",
    "ADB": "https://assets-currency.kucoin.com/60bf86ad8afb0a00068efd23_ADB.png",
    "AERGO": "https://assets-currency.kucoin.com/60bf86e08afb0a00068efd2b_AERGO.png",
    "AION": "https://assets-currency.kucoin.com/60bf87298afb0a00068efd3c_AION.png",
    "AMB": "https://assets-currency.kucoin.com/60bf87b3db892b0006d7371a_AMB.png",
    "AOA": "https://assets-currency.kucoin.com/60bf88298afb0a00068efd5d_AOA.png",
    "AVA": "https://assets-currency.kucoin.com/60bf88a9db892b0006d73744_AVA.png",
    "AXPR": "https://assets-currency.kucoin.com/60bf88ebdb892b0006d7374f_AXPR.png",
    "BAX": "https://assets-currency.kucoin.com/60bf890e8afb0a00068efd7f_BAX.png",
    "BCD": "https://assets-currency.kucoin.com/60bf8929db892b0006d73753_BCD.png",
    "BCH": "https://assets-currency.kucoin.com/60bf8948db892b0006d7375a_BCH.png",
    "BCHSV": "https://assets-currency.kucoin.com/60bf8a7e8afb0a00068efdb9_BSV.png",
    "BTC": "https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png",
    "BTCP": "https://assets-currency.kucoin.com/60bf8abedb892b0006d73790_BTCP.png",
    "CAPP": "https://assets-currency.kucoin.com/60bf8b868afb0a00068efdd6_CAPP.png",
    "CBC": "https://assets-currency.kucoin.com/617fa27edb9c2c0006153c2d_Icon%20for%20Black%20bg.png",
    "CHSB": "https://assets-currency.kucoin.com/60bf8c488afb0a00068efdfe_CHSB.png",
    "COV": "https://assets-currency.kucoin.com/60bf8cdcdb892b0006d737fc_COV.png",
    "CPC": "https://assets-currency.kucoin.com/60bf8cebdb892b0006d737ff_CPC.png",
    "CRPT": "https://assets-currency.kucoin.com/60bf8d0fdb892b0006d73803_CRPT.png",
    "CS": "https://assets-currency.kucoin.com/60bf8d2e8afb0a00068efe22_CS.png",
    "CV": "https://assets-currency.kucoin.com/60bf8d84db892b0006d7381c_CV.png",
    "CVC": "https://assets-currency.kucoin.com/60bf8d94db892b0006d7381e_CVC.png",
    "CXO": "https://assets-currency.kucoin.com/60bf8de08afb0a00068efe44_CXO.png",
    "DAG": "https://assets-currency.kucoin.com/60bf8e05db892b0006d7383d_DAG.png",
    "DAI": "https://assets-currency.kucoin.com/60bf8e118afb0a00068efe49_DAI.png",
    "DASH": "https://assets-currency.kucoin.com/60bf8e50db892b0006d73842_DASH.png",
    "DATX": "https://assets-currency.kucoin.com/60bf8e66db892b0006d7384a_DATX.png",
    "DCR": "https://assets-currency.kucoin.com/60bf8ea9db892b0006d73860_DCR.png",
    "DENT": "https://assets-currency.kucoin.com/60bf8ed5db892b0006d7386f_DENT.png",
    "DGB": "https://assets-currency.kucoin.com/60bf8f258afb0a00068efe7d_DGB.png",
    "DOCK": "https://assets-currency.kucoin.com/60bf8f868afb0a00068efe89_DOCK.png",
    "DRGN": "https://assets-currency.kucoin.com/60bf90038afb0a00068efe9a_DRGN.png",
    "ELA": "https://assets-currency.kucoin.com/60bf9095db892b0006d738a7_ELA.png",
    "ELF": "https://assets-currency.kucoin.com/60bf90badb892b0006d738ad_ELF.png",
    "ENJ": "https://assets-currency.kucoin.com/60bf90d8db892b0006d738b4_ENJ.png",
    "EOS": "https://assets-currency.kucoin.com/60bf90f98afb0a00068efecb_EOS.png",
    "EPRX": "https://assets-currency.kucoin.com/www/coin/pc/EPRX.png",
    "ETC": "https://assets-currency.kucoin.com/60bf91c68afb0a00068efeef_ETC.png",
    "ETF": "https://assets-currency.kucoin.com/60bf91d7db892b0006d738df_ETF.png",
    "ETH": "https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png",
    "ETN": "https://assets-currency.kucoin.com/60bf923adb892b0006d738f2_ETN.png",
    "FTM": "https://assets-currency.kucoin.com/60bf93248afb0a00068eff33_FTM.png",
    "GAS": "https://assets-currency.kucoin.com/60bf93478afb0a00068eff34_GAS.png",
    "GGC": "https://assets-currency.kucoin.com/60bf935b8afb0a00068eff39_GGC.png",
    "GMB": "https://assets-currency.kucoin.com/60bf937cdb892b0006d73926_GMB.png",
    "GO": "https://assets-currency.kucoin.com/60bf93908afb0a00068eff45_GO.png",
    "GOD": "https://assets-currency.kucoin.com/www/coin/pc/GOD.png",
    "HPB": "https://assets-currency.kucoin.com/60c03f3bdb892b0006d74d8b_HPB.png",
    "IOST": "https://assets-currency.kucoin.com/60c74269db892b0006d81979_IOST.png",
    "IOTX": "https://assets-currency.kucoin.com/60c742928afb0a00068fe18c_IOTX.png",
    "J8T": "https://assets-currency.kucoin.com/60c742ee8afb0a00068fe194_J8T.png",
    "KAT": "https://assets-currency.kucoin.com/60c74360db892b0006d819a3_KAT.png",
    "KCS": "https://assets-currency.kucoin.com/60c74375db892b0006d819a9_KCS.png",
    "KEY": "https://assets-currency.kucoin.com/60c743a0db892b0006d819b0_KEY.png",
    "KNC": "https://assets-currency.kucoin.com/60c743eddb892b0006d819b7_KNC.png",
    "LOC": "https://assets-currency.kucoin.com/60c7450adb892b0006d819e2_LOC.png",
    "LOOM": "https://assets-currency.kucoin.com/60c745aedb892b0006d819f2_LOOM.png",
    "LSK": "https://assets-currency.kucoin.com/60c745d98afb0a00068fe1eb_LSK.png",
    "LTC": "https://assets-currency.kucoin.com/60c745f2db892b0006d819f8_LTC.png",
    "LYM": "https://assets-currency.kucoin.com/60c7462bdb892b0006d819fd_LYM.png",
    "MAN": "https://assets-currency.kucoin.com/60c74665db892b0006d81a03_MAN.png",
    "MANA": "https://assets-currency.kucoin.com/60c74677db892b0006d81a0b_MANA.png",
    "MCT": "https://assets-currency.kucoin.com/60c746cddb892b0006d81a1e_MCT.png",
    "MKR": "https://assets-currency.kucoin.com/60c74a0c8afb0a00068fe279_MKR.png",
    "MVP": "https://assets-currency.kucoin.com/60c74be98afb0a00068fe2ea_MVP.png",
    "NEO": "https://assets-currency.kucoin.com/60c74ed3db892b0006d81b66_NEO.png",
    "NULS": "https://assets-currency.kucoin.com/60c75148db892b0006d81bc1_NULS.png",
    "OLT": "https://assets-currency.kucoin.com/60c757c4db892b0006d81cc8_OLT.png",
    "OMG": "https://assets-currency.kucoin.com/60c757d88afb0a00068fe4a0_OMG.png",
    "ONG": "https://assets-currency.kucoin.com/60c75848db892b0006d81cda_ONG.png",
    "ONION": "https://assets-currency.kucoin.com/60c7586adb892b0006d81ce6_ONION.png",
    "ONT": "https://assets-currency.kucoin.com/60c75886db892b0006d81ced_ONT.png",
    "PLAY": "https://assets-currency.kucoin.com/60c300298afb0a00068f6e04_PLAY.png",
    "POWR": "https://assets-currency.kucoin.com/60c30066db892b0006d7a6d6_POWR.png",
    "PPT": "https://assets-currency.kucoin.com/60c3007fdb892b0006d7a6da_PPT.png",
    "QKC": "https://assets-currency.kucoin.com/60c344248afb0a00068f7717_QKC.png",
    "QTUM": "https://assets-currency.kucoin.com/60c344588afb0a00068f771e_QTUM.png",
    "R": "https://assets-currency.kucoin.com/60c3486ddb892b0006d7b043_REV.png",
    "REQ": "https://assets-currency.kucoin.com/60c34890db892b0006d7b04a_REQ.png",
    "SNT": "https://assets-currency.kucoin.com/60c35a9edb892b0006d7b34a_SNT.png",
    "SNX": "https://assets-currency.kucoin.com/60c34933db892b0006d7b083_SNX.png",
    "SOUL": "https://assets-currency.kucoin.com/60c35aba8afb0a00068f7adb_SOUL.png",
    "SUSD": "https://assets-currency.kucoin.com/605dbfd895d4bc00065755f2_2927.png",
    "TEL": "https://assets-currency.kucoin.com/60c3611a8afb0a00068f7beb_TEL.png",
    "TIME": "https://assets-currency.kucoin.com/60c34a408afb0a00068f780c_TIME.png",
    "TKY": "https://assets-currency.kucoin.com/60c361828afb0a00068f7c05_TKY.png",
    "TOMO": "https://assets-currency.kucoin.com/5e5c854bf816040008d35f59_Asset_token.png",
    "TRAC": "https://assets-currency.kucoin.com/60c34a88db892b0006d7b0c7_TRAC.png",
    "TRX": "https://assets-currency.kucoin.com/60c34b5fdb892b0006d7b0dd_TRX.png",
    "TUSD": "https://assets-currency.kucoin.com/60c34b6c8afb0a00068f7839_TUSD.png",
    "USDC": "https://assets-currency.kucoin.com/5cfdbab438300c4320a531ec_usdc-icon.png",
    "USDT": "https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png",
    "UTK": "https://assets-currency.kucoin.com/60c34bd8db892b0006d7b0f7_UTK.png",
    "VET": "https://assets-currency.kucoin.com/613b133af115020006c61285_VET.png",
    "VTHO": "https://assets-currency.kucoin.com/60c34bf68afb0a00068f7859_VTHO.png",
    "WAN": "https://assets-currency.kucoin.com/60c363778afb0a00068f7c60_WAN.png",
    "WAX": "https://assets-currency.kucoin.com/60c3638bdb892b0006d7b4e2_WAXP.png",
    "WTC": "https://assets-currency.kucoin.com/60c36246db892b0006d7b4a2_WTC.png",
    "XLM": "https://assets-currency.kucoin.com/60c34c268afb0a00068f7867_XLM.png",
    "XRP": "https://assets-currency.kucoin.com/60c34c418afb0a00068f786d_XRP.png",
    "XYO": "https://assets-currency.kucoin.com/60c34c50db892b0006d7b111_XYO.png",
    "ZIL": "https://assets-currency.kucoin.com/60c364c58afb0a00068f7ca1_ZIL.png",
    "ZRX": "https://assets-currency.kucoin.com/60c34c5edb892b0006d7b115_ZRX.png",
    "GRIN": "https://assets-currency.kucoin.com/60c34c6ddb892b0006d7b118_GRIN.png",
    "SOLVE": "https://assets-currency.kucoin.com/6143120ff115020006c83cbe_IMAGE%202021-09-16%2010%3A56%3A51.jpg",
    "BTT": "https://assets-currency.kucoin.com/60bf8b018afb0a00068efdc5_BTT.png",
    "MHC": "https://assets-currency.kucoin.com/60c746f9db892b0006d81a25_MHC.png",
    "RVN": "https://s3-symbol-logo.tradingview.com/crypto/XTVCRVN.svg"
}

export default Icons