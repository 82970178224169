class Calculator {
    static slippage = ({price, entryPrice, side}) => {
        if (price === entryPrice) return 0
    
        let diff = (price < entryPrice) ? entryPrice - price : price - entryPrice
        
        diff = ((diff * 100)/price).toFixed(2) * 1
    
        if (side === 'BUY') return (price > entryPrice) ? diff : diff * -1
    
        if (side === 'SELL') return (price > entryPrice) ? diff * -1 : diff
        
    }

    static positionAmount = ({entryPrice, entryQty}) => (entryPrice * entryQty).toFixed(2) * 1

    static profitLossInPercentages = ({total, entryPrice, entryQty}) => ((total * 100)/Calculator.positionAmount({entryPrice, entryQty})).toFixed(2) * 1

    static realTimePnl = ({entryPrice, side}, rtPrice) => {
        let pal = ((rtPrice * 100)/entryPrice - 100).toFixed(2) * 1

        if (side === 'SELL') return pal * -1

        return pal
    }

    static realTimePnlAmt = (position, rtPrice) => {
        let rtPnl = Calculator.realTimePnl(position, rtPrice)
        let posAmt = Calculator.positionAmount(position)        
        return ((posAmt * rtPnl)/100)
    }
}

export default Calculator