import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { AppContext } from '../../contexts/AppContext';
import Calculator from '../../utils/Calculator';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
      legend: {
          position: 'top',
      },
      title: {
          display: true,
          text: 'Avg. Profit/Loss Positions %',
      },
  },
}

const ProfitLossPositionsPercentages = () => {
  const { positions } = useContext(AppContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!positions) return

    let profit = {total: 0, count: 0}, loss = {total: 0, count: 0}
    for (const value of Object.values(positions)) {
      for (let pos of value.closed) {       
        let calcResult = Calculator.profitLossInPercentages(pos)         
        if (calcResult < 0) {
          loss.total += calcResult
          loss.count += 1
        }
        else {
          profit.total += calcResult
          profit.count += 1
        }
      }
    }
    
    profit = profit.total/profit.count
    loss = loss.total/loss.count

    setData({
      labels: [
        `Profit (${profit.toFixed(2)}%)`,
        `Loss (${loss.toFixed(2)}%)`,        
      ],
      datasets: [
        {
          label: 'Closed Positions',
          data: [profit, loss],
          backgroundColor: [
            'hsl(113, 82.9%, 67.8%)',            
            'hsl(0, 82.9%, 67.8%)',
          ],
          borderColor: [
            'hsl(113, 82.9%, 67.8%)',            
            'hsl(0, 82.9%, 67.8%)',
          ],
          borderWidth: 1,
        },
      ],
    })
  }, [positions])

  return data ? <Pie data={data} options={options}/> : <></>
}

export default ProfitLossPositionsPercentages