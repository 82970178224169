import { createContext, useEffect, useState } from "react"
import Stream from "../utils/Stream"

const sanitizeData = (data) => {
    let tmp = {}
    data.positions.reverse()
    for (let p of data.positions) {
        if (!tmp[p.symbol]) tmp[p.symbol] = { closed: [], opened: [] }

        let trades = []
        
        let total = 0
        let pnl = 0
        for (let t of data.trades) {
            if (t.position !== p._id) continue
            pnl += t.pnl
            total += t.pnl - t.commission
            trades.push(t)
        }

        p.trades = trades
        p.pnl = pnl
        p.total = total

        if (p.closed) {
            tmp[p.symbol].closed.push(p)
        }
        else {
            tmp[p.symbol].opened.push(p)
        }

    }
    return tmp
}

export const AppContext = createContext(null)

const initSources = [
    {
        name: 'Alina',
        url: 'https://s1.signalstx.com/api/all',
        root: 'https://s1.signalstx.com'
    },
    {
        name: 'Dima',
        url: 'https://s3.signalstx.com/api/all',
        root: 'https://s3.signalstx.com'
    },
    {
        name: 'Vadim',
        url: 'https://s2.signalstx.com/api/all',
        root: 'https://s2.signalstx.com'

        // url: 'http://localhost:3003/api/all',
        // root: 'http://localhost:3003'
    },
    {
        name: 'Jenya',
        url: 'https://s4.signalstx.com/api/all',
        root: 'https://s4.signalstx.com'
    },
    {
        name: 'Sergey',
        url: 'https://s5.signalstx.com/api/all',
        root: 'https://s5.signalstx.com'
    },
    {
        name: 'Misha',
        url: 'https://s6.signalstx.com/api/all',
        root: 'https://s6.signalstx.com'
    },
]

export const AppContextProvider = ({ children }) => {

    const [positions, setPositions] = useState(null)
    const [symbols, setSymbols] = useState(null)
    const [userSettings, setUserSettings] = useState(null)
    const [source, setSource] = useState(localStorage.getItem('datasource') || 0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let timer = null
        const fetchData = async () => {
            setLoading(prev => true)
            try {
                console.log('FETCH DATA')
                let data = await fetch(initSources[source].url)
                data = await data.json()
                let tmp = sanitizeData(data)

                console.log(`Server Data`, data)
                console.log(`Sanitized Data`, tmp)
                console.log(`Symbols`, [...Object.keys(tmp)])

                setPositions(prev => ({ ...tmp }))
                setUserSettings(prev => ({ ...data.user }))
                setSymbols(prev => ([...Object.keys(tmp)]))

                Stream.symbols = [...Object.keys(tmp)]

                setLoading(prev => false)

                window.clearInterval(timer)
                timer = window.setInterval(fetchData, 5 * 60 * 1000)
            }
            catch (err) {
                console.log(err)
                return new Error()
            }
        }

        fetchData()
        localStorage.setItem('datasource', source)

        return () => window.clearInterval(timer)

    }, [source])

    const changeDataSource = sourceId => setSource(sourceId)

    return (
        <AppContext.Provider value={{ positions, symbols, userSettings, initSources, source, changeDataSource, loading }}>
            {children}
        </AppContext.Provider>
    )
}

