import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { AppContext } from '../../contexts/AppContext'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Profit/Loss by Symbol $',
        },
    },
}

const dataInit = {
    labels: [],
    datasets: [],
}

const ProfitLossPositionsBySymbolUsd = () => {
    const { positions } = useContext(AppContext)
    const [data, setData] = useState(dataInit)

    useEffect(() => {
        if (!positions) return

        let labels = [], datasets = [], symbols = {}        
        for (const [symbol, value] of Object.entries(positions)) {
            labels.push(symbol)
            symbols[symbol] = {p: 0, l: 0}

            for (let pos of value.closed) {
                if (pos.total < 0) symbols[symbol].l += pos.total
                else symbols[symbol].p += pos.total
            }
        }

        let setOfProfit = [], setOfLoss = []
        for (let s of labels) {
            setOfProfit.push(symbols[s].p)
            setOfLoss.push(symbols[s].l)
        }

        datasets = [
            {
                label: `Profit`,
                backgroundColor: "hsl(113, 82.9%, 67.8%)",
                borderColor: "hsl(113, 82.9%, 67.8%)",
                data: setOfProfit,
            },          
            {
                label: `Loss`,
                backgroundColor: "hsl(0, 82.9%, 67.8%)",
                borderColor: "hsl(0, 82.9%, 67.8%)",
                data: setOfLoss,
            },
        ]

        setData(prev => ({ ...prev, datasets, labels }))

    }, [positions])

    return (
        <>
            <Bar data={data} options={options} />
        </>
    )
}

export default ProfitLossPositionsBySymbolUsd