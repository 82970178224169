import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="w-full flex flex-col justify-start items-center bg-white">
            <h1 className="text-9xl font-extrabold text-gray-800 tracking-widest">404</h1>
            <div className="px-2 text-lg rounded rotate-0 mt-4 mb-8">
                Page Not Found
            </div>
            <button className="mt-5">
                <Link to="/" className="underline font-bold">Go Home</Link>
            </button>
        </div>
    )
}

export default NotFound