import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { AppContext } from '../../contexts/AppContext'
import Calculator from '../../utils/Calculator'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

let options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Avg. Slippage Closed Positions %',
        },
    },
}

const dataInit = {
    labels: [],
    datasets: [],
}

const SlippagePositions = () => {
    const { positions } = useContext(AppContext)
    const [data, setData] = useState(dataInit)

    useEffect(() => {
        if (!positions) return

        let labels = [], datasets = [], symbols = {}, slippageSum = 0, posCounter = 0
        for (const [symbol, value] of Object.entries(positions)) {
            labels.push(symbol)
            symbols[symbol] = { slipageNegative: {total: 0, count: 0 }, slipagePositive: {total: 0, count: 0 }}

            for (let pos of value.closed) {
                let slippage = Calculator.slippage(pos)
                slippageSum += slippage//(slippage < 0) ? slippage * -1 : slippage
                posCounter += 1

                if (slippage < 0) {
                    symbols[symbol].slipageNegative.total += (slippage < 0) ? slippage * -1 : slippage
                    symbols[symbol].slipageNegative.count += 1
                }
                else {
                    symbols[symbol].slipagePositive.total += (slippage < 0) ? slippage * -1 : slippage
                    symbols[symbol].slipagePositive.count += 1
                }
            }
        }

        let setOfPositive = [], setOfNegative = []
        for (let s of labels) {
            setOfPositive.push((symbols[s].slipagePositive.total / symbols[s].slipagePositive.count).toFixed(2))
            setOfNegative.push((symbols[s].slipageNegative.total / symbols[s].slipageNegative.count).toFixed(2) * -1)
        }

        options.plugins.title.text = `Avg. Slippage Closed Positions (${(slippageSum/posCounter).toFixed(2)}%)`

        datasets = [
            {
                label: `Positive`,
                backgroundColor: "hsl(113, 82.9%, 67.8%)",
                borderColor: "hsl(113, 82.9%, 67.8%)",
                data: setOfPositive,
            },
            {
                label: `Negative`,
                backgroundColor: "hsl(0, 82.9%, 67.8%)",
                borderColor: "hsl(0, 82.9%, 67.8%)",
                data: setOfNegative,
            },
        ]

        setData(prev => ({ ...prev, datasets, labels }))

    }, [positions])

    return (
        <>
            <Bar data={data} options={options} />
        </>
    )
}

export default SlippagePositions