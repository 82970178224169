import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon, ChartPieIcon } from '@heroicons/react/outline'
import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from "react-router-dom"
import { AppContext } from '../contexts/AppContext'
import Icons from '../utils/Icons'

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
    let location = useLocation()
    const { symbols, positions } = useContext(AppContext)    
    const [navigation, setNavigation] = useState([])

    useEffect(() => {
        if (positions === null) return

        let newItems = [{ name: 'Dashboard', href: '/'}]
        for (let s of symbols) {

            newItems.push(
                {
                    name: s.split('USDT')[0],
                    href: `/symbol/${s}`,
                    opened: positions[s].opened.length > 0
                }
            )
        }

        setNavigation(newItems)

    }, [positions, symbols])

    useEffect(() => {                
        for (let nav of navigation) {
            if (nav.href !== location.pathname) continue
            document.title = nav.name            
        }
    }, [location.pathname, navigation])

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center">
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        {
                                            navigation.map((item) => (
                                                <span key={item.name} className="flex items-center">                                                                                                                                                           
                                                    {item.name !== 'Dashboard' ? <img src={`${Icons[item.name.toUpperCase()]}`} alt={item.name} className="w-8 h-8" /> : <ChartPieIcon className="block w-8 h-8 text-white"/>}
                                                    <Link
                                                        to={item.href}
                                                        className={classNames(
                                                            item.href === location.pathname ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                            'px-3 py-2 rounded-md text-sm font-medium text-center ml-4 relative'
                                                        )}
                                                        aria-current={item.href === location.pathname ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                        {item.opened && <span className='absolute rounded bg-red-500 w-2 h-2 -right-0 -top-0'></span>} 
                                                    </Link>
                                                </span>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-4">
                            {navigation.map((item) => (
                                <div className='flex items-center' key={item.name}>                                    
                                    {item.name !== 'Dashboard' ? <img src={`${Icons[item.name.toUpperCase()]}`} alt={item.name} className="w-8 h-8 ml-4" /> : <ChartPieIcon className="w-8 h-8 ml-4 text-white"/>}
                                    <Disclosure.Button
                                        as={Link}
                                        to={item.href}
                                        className={classNames(
                                            item.href === location.pathname ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'block px-3 py-2 rounded-md text-base font-medium ml-4 relative'
                                        )}
                                        aria-current={item.href === location.pathname ? 'page' : undefined}
                                    >
                                        {item.name}
                                        {item.opened && <span className='absolute rounded bg-red-500 w-2 h-2 -right-0 -top-0'></span>} 
                                    </Disclosure.Button>
                                </div>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default NavBar
