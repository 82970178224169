import moment from 'moment'

const thStyle = `border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left`
const tdStyle = `border-b border-slate-100 dark:border-slate-700 p-4 pl-8`

const TradesPanel = ({ trades }) => {
    return (
        <table className="border-collapse table-auto w-full text-sm my-8">
            <thead>
                <tr>
                    <th className={thStyle}>Action</th>
                    <th className={thStyle}>OrderId</th>
                    <th className={thStyle}>Pnl</th>
                    <th className={thStyle}>Commission</th>
                    <th className={thStyle}>Date</th>
                </tr>
            </thead>
            <tbody className="dark:bg-slate-800 bg-gray-200">
                {
                    trades.map(t => {
                        return (
                            <tr key={new Date(t.createdAt).getTime()}>
                                <td className={tdStyle}>
                                    {t.action === 'o' && `OPEN - ${t.side} MARKET`}
                                    {t.action === 'c' && `CLOSE - ${t.side} MARKET`}
                                    {t.action === 'tc' && `CLOSE - ${t.side} TAKE PROFIT`}
                                    {t.action === 'sc' && `CLOSE - ${t.side} STOP`}
                                    {t.action === 'tsc' && `CLOSE - ${t.side} TRAILING STOP`}
                                    {t.action === 't1' && `${t.side} TAKE PROFIT LIMIT`}
                                    {t.action === 't2' && `${t.side} TAKE PROFIT LIMIT`}
                                </td>
                                <td className={tdStyle}>{t.orderId}</td>
                                <td className={tdStyle}>{t.pnl.toFixed(4)}</td>
                                <td className={tdStyle}>{t.commission.toFixed(6)}</td>
                                <td className={tdStyle}>{moment(t.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
}

export default TradesPanel