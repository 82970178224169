import './App.css'
import { AppContextProvider } from './contexts/AppContext'
import AppRouter from './routes/AppRouter'

const App = () => {
  return (
    <AppContextProvider>
      <AppRouter />
    </AppContextProvider>
  )
}

export default App