import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import moment from 'moment'
import Calculator from '../utils/Calculator'
import ProfitLossCell from './ProfitLossCell'
import TradesPanel from './TradesPanel'

const thStyle = `border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left`
const tdStyle = `border-b border-slate-100 dark:border-slate-700 p-4 pl-8`

const TableOpened = ({ data, symbol }) => {

  const calculateOpenedCommision = ({trades}) => {    
      let totalCommision = 0
      for (let trade of trades) {
          totalCommision += trade.commission
      }

      return totalCommision
  }

  return (
    <table className="border-collapse table-auto w-full text-sm">
      <thead>
        <tr>
          <th className={thStyle}>Entry Date</th>
          <th className={thStyle}>Side</th>
          <th className={thStyle}>Size</th>
          <th className={thStyle}>Signal Price</th>
          <th className={thStyle}>Entry Price</th>
          <th className={thStyle}>Slippage</th>          
          <th className={thStyle}>Strategy</th>
          <th className={thStyle}>Commission</th>
          <th className={thStyle}>Pnl</th>          
          <th className={thStyle}>Pnl(%)</th>          
          <th className={thStyle}>Trades</th>
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-slate-800">
        {
          data.map(d => {
            return (
              <Disclosure key={d._id}>
                {({ open }) => (
                  <>
                    <tr>
                      <td className={tdStyle}>{moment(d.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                      <td className={`${tdStyle} ${d.side === 'BUY' ? 'text-green-500' : 'text-red-500'}`}>{d.side === 'BUY' ? 'LONG' : 'SHORT'}</td>
                      <td className={tdStyle}>${Calculator.positionAmount(d)}</td>
                      <td className={tdStyle}>{d.price}</td>
                      <td className={tdStyle}>{d.entryPrice}</td>
                      <td className={`${tdStyle} ${(Calculator.slippage(d) < 0) ? 'text-red-500' : 'text-green-500'}`}>{Calculator.slippage(d)}%</td>                      
                      <td className={tdStyle}>{d.strategy}</td>
                      <td className={tdStyle}>${calculateOpenedCommision(d).toFixed(6)}</td>                   
                      <td className={tdStyle}><ProfitLossCell symbol={symbol} position={d} type="amount"/></td>                      
                      <td className={tdStyle}><ProfitLossCell symbol={symbol} position={d} type="percentage"/> </td>
                      <td className={tdStyle}>
                        <Disclosure.Button className="flex justify-center rounded-lg bg-gray-100 px-2 py-2 text-center text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                          <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                        </Disclosure.Button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={11}>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          <TradesPanel trades={d.trades} />
                        </Disclosure.Panel>
                      </td>
                    </tr>
                  </>
                )}
              </Disclosure>
            )
          })
        }
      </tbody>
    </table>
  )
}
export default TableOpened