import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { AppContext } from '../../contexts/AppContext';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
      legend: {
          position: 'top',
      },
      title: {
          display: true,
          text: 'Profit/Loss Positions',
      },
  },
}

const ProfitLossPositions = () => {
  const { positions } = useContext(AppContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!positions) return

    let positive = 0, negative = 0, totalClosed = 0
    for (const value of Object.values(positions)) {
      totalClosed += value.closed.length

      for (let pos of value.closed) {        
        if (pos.total < 0) negative += 1
        else positive += 1
      }
    }

    setData({
      labels: [
        `Profit (${(positive * 100 / totalClosed).toFixed(1)}%)`,
        `Loss (${(negative*100/totalClosed).toFixed(1)}%)`,        
      ],
      datasets: [
        {
          label: 'Closed Positions',
          data: [positive, negative],
          backgroundColor: [
            'hsl(113, 82.9%, 67.8%)',            
            'hsl(0, 82.9%, 67.8%)',
          ],
          borderColor: [
            'hsl(113, 82.9%, 67.8%)',            
            'hsl(0, 82.9%, 67.8%)',
          ],
          borderWidth: 1,
        },
      ],
    })
  }, [positions])

  return data ? <Pie data={data} options={options}/> : <></>
}

export default ProfitLossPositions