import ClosedPositionsBySymbol from "../components/Charts/ClosedPositionsBySymbol"
import AllClosedPositionsPieChart from "../components/Charts/AllClosedPositionsPieChart"
import ProfitLossPositions from "../components/Charts/ProfitLossPositions"
import ProfitLossPositionsBySymbol from "../components/Charts/ProfitLossPositionsBySymbol"
import ProfitLossPositionsUsd from "../components/Charts/ProfitLossPositionsUsd"
import ProfitLossPositionsBySymbolUsd from "../components/Charts/ProfitLossPositionsBySymbolUsd"
import ProfitLossPositionsPercentages from "../components/Charts/ProfitLossPositionsPercentages"
import ProfitLossPositionsBySymbolPercentages from "../components/Charts/ProfitLossPositionsBySymbolPercentages"
import SlippagePositions from "../components/Charts/SlippagePositions"
import SummaryTable from "../components/SummaryTable"
import { useContext } from "react"
import { AppContext } from "../contexts/AppContext"
import { TickerContextProvider } from "../contexts/TickerContext"
import DepositChanges from "../components/Charts/DepositChanges"

const Dashboard = () => {
  const { loading } = useContext(AppContext)

  return !loading ? (
    <>
      <div className="grid gap-12 grid-cols-3">
        <div className="col-span-3">
          <TickerContextProvider>
            <SummaryTable />
          </TickerContextProvider>
        </div>
      </div>
      <div className="grid gap-12 grid-cols-3 mt-20 border-t-2 pt-20">
        <div className="col-span-3">
          <DepositChanges />
        </div>
      </div>
      <div className="grid gap-12 grid-cols-3 mt-20 border-t-2 pt-20">
        <div className="col-span-3 md:col-span-1">
          <AllClosedPositionsPieChart />
        </div>
        <div className="col-span-3 md:col-span-2">
          <ClosedPositionsBySymbol />
        </div>
      </div>
      <div className="grid gap-12 grid-cols-3 mt-20 border-t-2 pt-20">
        <div className="col-span-3 md:col-span-1">
          <ProfitLossPositions />
        </div>
        <div className="col-span-3 md:col-span-2">
          <ProfitLossPositionsBySymbol />
        </div>
      </div>
      <div className="grid gap-12 grid-cols-3 mt-20 border-t-2 pt-20">
        <div className="col-span-3 md:col-span-1">
          <ProfitLossPositionsUsd />
        </div>
        <div className="col-span-3 md:col-span-2">
          <ProfitLossPositionsBySymbolUsd />
        </div>
      </div>
      <div className="grid gap-12 grid-cols-3 mt-20 border-t-2 pt-20">
        <div className="col-span-3 md:col-span-1">
          <ProfitLossPositionsPercentages />
        </div>
        <div className="col-span-3 md:col-span-2">
          <ProfitLossPositionsBySymbolPercentages />
        </div>
      </div>

      <div className="grid gap-12 grid-cols-3 mt-20 border-t-2 pt-20">
        <div className="col-span-3">
          <SlippagePositions />
        </div>
      </div>
    </>
  ) : (<div className="w-full text-center text-2xl">LOADING...</div>)
}

export default Dashboard