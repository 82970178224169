import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ClosePositionButton from "../components/ClosePositionButton"
import TableClosed from "../components/TableClosed"
import TableOpened from "../components/TableOpened"
import { AppContext } from "../contexts/AppContext"
import { TickerContextProvider } from "../contexts/TickerContext"

const Symbol = () => {
    let params = useParams()
    let navigate = useNavigate()
    const { positions } = useContext(AppContext)
    const [totalClosed, setTotalClosed] = useState(0)

    useEffect(() => {
        if (!positions) return

        if (!positions[params.name]) {
            navigate("/404", { replace: true })
        }

        if (positions[params.name] && positions[params.name].closed.length) {
            let closed = 0
            for (let p of positions[params.name].closed) {
                closed += p.total
            }

            setTotalClosed(closed)
        }

    }, [positions, params.name, navigate])

    return positions ? (
        <>
            <div className="bg-white mb-4">
                <div className="w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-1xl font-bold text-gray-900">Opened Positions</h1>
                    {(positions && positions[params.name] && positions[params.name].opened.length) && <ClosePositionButton />}
                </div>
            </div>
            <div>
                {
                    (positions && positions[params.name] && positions[params.name].opened.length) ?
                        <TickerContextProvider><TableOpened data={positions[params.name].opened} symbol={params.name} /></TickerContextProvider> :
                        <div className="text-center">No opened positions</div>
                }
            </div>

            <div className="bg-white my-6">
                <div className="w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-1xl font-bold text-gray-900">Closed Positions - (<span className={`${totalClosed > 0 ? 'text-green-500' : 'text-red-500'}`}>${totalClosed.toFixed(2)}</span>)</h1>
                </div>
            </div>
            <div>
                {
                    (positions && positions[params.name] && positions[params.name].closed.length) ? <TableClosed data={positions[params.name].closed} symbol={params.name} /> : <div className="text-center">No closed positions</div>
                }
            </div>
        </>
    ) : <></>
}

export default Symbol