class Stream {
    _symbols = []
    _ticker = {}
    ws = null
    _reconnections = 0

    constructor() {
        this.startStream()
    }

    startStream() {
        this._reconnections += 1
        console.log('START STREAM RECONNECTION', this._reconnections)

        if (this._reconnections > 10) {
            this.ws = null
            return
        }
        else {
            this.ws = new WebSocket(`wss://fstream.binance.com/stream?streams=!ticker@arr`)

            this.ws.onmessage = event => {
                const { data, stream } = JSON.parse(event.data)
                if (stream === '!ticker@arr') {
                    this._reconnections = 0
                    let tickers = {}
                    for (let d of data) {
                        if (this._symbols.length === 0 || !this._symbols.includes(d.s)) continue
                        tickers[d.s] = { last: d.c * 1, changed: 0 }
                    }

                    if (Object.keys(this._ticker).length) {
                        for (let t of Object.keys(this._ticker)) {
                            if (!tickers[t]) continue
                            if (this._ticker[t].last === tickers[t].last) tickers[t].changed = 0
                            if (this._ticker[t].last > tickers[t].last) tickers[t].changed = -1
                            if (this._ticker[t].last < tickers[t].last) tickers[t].changed = 1
                        }
                    }

                    this._ticker = { ...this._ticker, ...tickers }
                }
                else {
                    console.log(stream, data)
                }
            }

            this.ws.onclose = () => {
                console.log('ws closed')
                this.ws = null
                this.startStream()
            }

            this.ws.addEventListener('ping', e => {
                console.log('ping')
                this.ws.pong()
            })

            this.ws.onerror = event => {
                console.log('ws error')
                this.ws = null
                this.startStream()
            }
        }
    }

    set symbols(symbols) {
        this._symbols = symbols
    }

    get symbols() {
        return this._symbols
    }

    get ticker() {
        return this._ticker
    }


}


export default new Stream()