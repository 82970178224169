import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { AppContext } from '../../contexts/AppContext';

ChartJS.register(ArcElement, Tooltip, Legend);

let options = {
  responsive: true,
  plugins: {
      legend: {
          position: 'top',
      },
      title: {
          display: true,
          text: 'Closed Positions',
      },
  },
}

const AllClosedPositionsPieChart = () => {
  const { positions } = useContext(AppContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!positions) return

    let tc = 0, sc = 0, c = 0, tsc = 0, totalClosed = 0
    for (const value of Object.values(positions)) {
      totalClosed += value.closed.length
      for (let pos of value.closed) {
        for (let trade of pos.trades) {
          if (trade.action === 'c') {
            c += 1
            break
          }
          if (trade.action === 'tc') {
            tc += 1
            break
          }
          if (trade.action === 'sc') {
            sc += 1
            break
          }
          if (trade.action === 'tsc') {
            tsc += 1
            break
          }
        }
      }
    }

    options.plugins.title.text = `Closed Positions (${totalClosed})`

    setData({
      labels: [
        `Take Profit (${(tc * 100 / totalClosed).toFixed(1)}%)`,
        `Market (${(c*100/totalClosed).toFixed(1)}%)`,
        `Stop Loss (${(sc*100/totalClosed).toFixed(1)}%)`,
        `Trailing Stop (${(tsc*100/totalClosed).toFixed(1)}%)`
      ],
      datasets: [
        {
          label: 'Closed Positions',
          data: [tc, c, sc, tsc],
          backgroundColor: [
            'hsl(113, 82.9%, 67.8%)',
            'hsl(39, 82.9%, 67.8%)',
            'hsl(0, 82.9%, 67.8%)',
            'hsl(227, 82.9%, 67.8%)',
          ],
          borderColor: [
            'hsl(113, 82.9%, 67.8%)',
            'hsl(39, 82.9%, 67.8%)',
            'hsl(0, 82.9%, 67.8%)',
            'hsl(227, 82.9%, 67.8%)',
          ],
          borderWidth: 1,
        },
      ],
    })
  }, [positions])

  return data ? <Pie data={data} options={options}/> : <></>
}

export default AllClosedPositionsPieChart