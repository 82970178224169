import { useContext, useState } from "react"
import { useParams } from "react-router-dom"
import { AppContext } from "../contexts/AppContext"

const ClosePositionButton = () => {
    const params = useParams()
    const { initSources } = useContext(AppContext)
    const [disabled, setDisabled] = useState(false)

    const closePosition = async () => {        
        try {
            if (disabled === true) return

            console.log('HERE')

            setDisabled(true)

            const source = localStorage.getItem('datasource')
            if (source !== null) {
                const root = initSources[source].root
                const response = await fetch(
                    `${root}/api/close`,
                    {
                        method: 'POST',
                        body: JSON.stringify({
                            symbol: params.name
                        }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )

                const data = await response.json()
                if (data.closed === true) {
                    window.location.reload()
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <button onClick={closePosition} className={`rounded-md border-white border-2 ${disabled === true ? 'bg-green-900' : 'bg-green-600'} text-white p-2 mt-2`}>
            Close Position {params.name}
            {
                disabled === true &&
                <svg className="animate-spin mr-1 ml-3 h-5 w-5 text-white inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            }
        </button>
    )
}

export default ClosePositionButton