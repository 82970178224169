import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../contexts/AppContext"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: 'Deposit',
            padding: 20,
            font: {size: 12}
        },
        legend: {
            display: false
        },
        tooltip: {
            intersect: false
        }
    }
}

const DepositChanges = () => {
    const { positions, userSettings } = useContext(AppContext)
    const [data, setData] = useState(null)

    useEffect(() => {
        if (!positions) return

        let weeks = {}
        let weekStartAndEnd = {}

        weeks[0] = userSettings.deposit

        for (let pos of Object.values(positions)) {
            for (let closed of pos.closed) {
                let week = moment(closed.updatedAt).week()
                if (!weeks[week]) weeks[week] = []
                if (!weekStartAndEnd[week]) {
                    weekStartAndEnd[week] = `${moment(closed.updatedAt).startOf('week').format('DD/MM')}-${moment(closed.updatedAt).endOf('week').format('DD/MM/YY')}`
                } 

                weeks[week].push(closed)
            }
        }

        let result = []
        let labels = []
        let deposit = userSettings.deposit
        for (let week of Object.keys(weeks).sort()) {
            if (week * 1 === 0) {
                result.push(deposit) 
                labels.push('start')
                continue
            }         

            for (let closed of weeks[week]) {
                deposit += closed.total
            }
            
            labels.push(weekStartAndEnd[week])
            result.push(deposit.toFixed(2))
        }

        setData(prev => ({
            labels: labels,
            datasets: [
                {                    
                    data: result,
                    borderColor: 'hsl(227, 82.9%, 67.8%)',
                    backgroundColor: 'hsl(227, 82.9%, 67.8%)',
                }              
            ],
        }))

    }, [positions, userSettings])

    return data && <Line options={options} data={data} />
}

export default DepositChanges