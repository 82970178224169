import { useContext } from "react"
import { AppContext } from "../contexts/AppContext"
import { useParams } from "react-router-dom"
import { TickerContext } from "../contexts/TickerContext"
import Icons from "../utils/Icons"

const btnCss = 'rounded-md px-4 mx-4 hover:bg-gray-700 cursor-pointer'

const Header = () => {
    let params = useParams()
    const { initSources, source, changeDataSource } = useContext(AppContext)
    const { ticker } = useContext(TickerContext)    
    return (
        <header className="bg-white shadow">
            {
                (!params.name && !params['*']) ? <div className="w-full mx-auto py-6 px-4 sm:px-6 lg:px-8 bg-gray-800 border-t-[1px] border-t-gray-600">
                    <div className="flex items-center justify-center text-white">
                        {
                            initSources.map((s, i) => {
                                return (<span key={i} className={`${btnCss} ${(source * 1 === i) ? 'bg-gray-900' : 'bg-gray-800'}`} onClick={() => { changeDataSource(i) }}>{s.name}</span>)
                            })
                        }
                    </div>
                </div> : <></>
            }
            <div className="w-full mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">
                    {(params.name) ? 
                        <span><img src={`${Icons[params.name.toUpperCase().split('USDT')[0]]}`} alt={params.name} className="w-8 h-8 inline-block" /> {params.name.toUpperCase()}</span> : 'Dashboard'}
                    {(ticker && ticker[params.name]) ? <span className={`pl-8 ${(ticker[params.name].changed === 0) ? 'text-black' : (ticker[params.name].changed === 1) ? 'text-green-500' : 'text-red-500'}`}>${ticker[params.name].last}</span> : ''}
                </h1>
            </div>
        </header>
    )
}

export default Header