import { useContext, useEffect, useState } from "react"
import { TickerContext } from "../contexts/TickerContext"
import Calculator from "../utils/Calculator"

/**
 * 
 * @param {String} symbol 
 * @param {Object} position
 * @param {String} type (percentage || amount) 
 * @returns 
 */
const ProfitLossCell = ({ symbol, position, type }) => {
    const { ticker } = useContext(TickerContext)
    const [pnl, setPnl] = useState((type === 'percentage') ? Calculator.profitLossInPercentages(position) : (type === 'amount') ? position.pnl : 0)

    useEffect(() => {
        if (ticker?.[symbol]) {
            if (type === 'percentage') {
                setPnl(Calculator.realTimePnl(position, ticker[symbol].last))
            }

            if (type === 'amount') {
                setPnl(Calculator.realTimePnlAmt(position, ticker[symbol].last))
            }
        }
    }, [ticker, position, symbol, type])

    return (
        <span className={`${pnl > 0 ? 'text-green-500' : 'text-red-500'}`}>
            {type === 'amount' ? '$' : ''}{(pnl && pnl === 0) ? '0.00' : pnl.toFixed(2)}{type === 'percentage' ? '%' : ''}
        </span>
    )

}

export default ProfitLossCell