import { createContext, useEffect, useState } from "react"
import Stream from "../utils/Stream"

export const TickerContext = createContext(null)

export const TickerContextProvider = ({ children }) => {
    const [ticker, setTicker] = useState(null)    

    useEffect(() => {    

        const updateTicker = () => {            
            setTicker(prev => ({...Stream.ticker}))
            window.setTimeout(updateTicker, 2000)
        }

        updateTicker()

        return () => {}

    }, [])


    return (
        <TickerContext.Provider value={{ ticker }}>
            {children}
        </TickerContext.Provider>
    )
}