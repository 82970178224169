import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useContext, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { AppContext } from '../../contexts/AppContext';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  plugins: {
      legend: {
          position: 'top',
      },
      title: {
          display: true,
          text: 'Profit/Loss Positions $',
      },
  },
}

const ProfitLossPositionsUsd = () => {
  const { positions } = useContext(AppContext)
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!positions) return

    let profit = 0, loss = 0
    for (const value of Object.values(positions)) {
      for (let pos of value.closed) {        
        if (pos.total < 0) loss += pos.total
        else profit += pos.total
      }
    }

    setData({
      labels: [
        `Profit (${profit.toFixed(2)}$)`,
        `Loss (${loss.toFixed(2)}$)`,        
      ],
      datasets: [
        {
          label: 'Closed Positions',
          data: [profit, loss],
          backgroundColor: [
            'hsl(113, 82.9%, 67.8%)',            
            'hsl(0, 82.9%, 67.8%)',
          ],
          borderColor: [
            'hsl(113, 82.9%, 67.8%)',            
            'hsl(0, 82.9%, 67.8%)',
          ],
          borderWidth: 1,
        },
      ],
    })
  }, [positions])

  return data ? <Pie data={data} options={options}/> : <></>
}

export default ProfitLossPositionsUsd