import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Symbol from "../pages/Symbol";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />} name="dashboard">
        <Route index element={<Dashboard />} name="dashboard"/>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/symbol/:name" element={<Symbol />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default AppRouter